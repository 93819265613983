.popup {
  position: fixed;
  @include full;
  z-index: 999;
  visibility: hidden;

  &.open {
    visibility: visible;

    .popup-overlay {
      opacity: 1;
      visibility: visible;
    }

    .popup-main {
      opacity: 1;
      visibility: visible;
    }
  }

  &-overlay {
    position: absolute;
    background-color: rgba($color: $color-black, $alpha: 0.8);
    @include full;
    z-index: 1;
    @include transitionRe;
    opacity: 0;
    visibility: hidden;
    // backdrop-filter: blur(0.3rem)
  }

  &-main {
    @include mid;
    z-index: 2;
    width: 90rem;
    max-height: 90vh;
    max-width: 95%;
    @include transitionRe;
    opacity: 0;
    visibility: hidden;
    position: relative;
    // background-color: $color-white;
    // padding: 1rem 0;
    background-color: $color-white;
    padding: 2.4rem;

    &.med {
      width: 90rem;
    }

    &.small {
      width: 80rem;
    }
  }

  &-over {
    overflow-y: auto;
    min-height: 40vh;
    max-height: 80vh;
  }

  &-wrapper {
    width: 100%;
    min-height: 40vh;
    position: relative;
    border-radius: 6px;
    @include mid-flex;
  }

  &-title {
    @include fz-24;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.6rem;
  }

  &-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 3rem;
    height: 3rem;
    @include mid-flex;
    z-index: 100;

    &:hover {
      .icon {
        transform: rotate(180deg);
      }
    }

    .icon {
      color: $color-black;
      font-weight: 300;
      font-size: 2.4rem;
      @include transition(0.6s, ease-in-out);

      @include max(800px) {
        @include fz-16;
      }
    }
  }

  &-search {
    width: 100%;
    padding: 1rem;
    padding-top: 5rem;

    &-box {
      width: 40rem;

      .popup-over {
        max-height: 90vh;
      }
    }
    &-logo {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 50%;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
    &-icon {
      .icon {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
      i {
        font-size: 4rem;
        font-weight: 700;
      }
    }

    &-input {
      // &::before {
      //     content: '';
      //     background-image: url("../assets/images/SEO-pana.png");
      //     @include default-background;
      //     width: 20rem;
      //     height: 20rem;
      //     display: block;
      //     margin: auto;
      //     margin-bottom: 1rem;
      // }

      &.active {
        &::before {
          display: none;
        }
      }

      input {
        width: 100%;
        transition: 0.4s;
        padding: 1rem 1.6rem;
        // border-radius: 100rem;
        border: 0.1rem solid $color-pri;
        height: 4.4rem;
      }

      .btn {
        width: 100%;
        margin-top: 1rem;
        height: 4.4rem;
        justify-content: center;
      }
    }

    .search-rs {
      top: 27rem !important;
      z-index: 20 !important;
      position: unset !important;
      max-height: 42rem !important;

      .submit-search {
        margin: auto;
        margin-top: 3rem;
        padding: 0.5rem 1rem;
        background-color: $color-pri;
        color: $color-white;
        @include mid-flex;
      }
    }

    .mona-search-form {
    }
  }

  &-bg {
    padding: 5.6rem 7rem;
    position: relative;
    overflow: hidden;
    .dc {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) translateX(50%);
      z-index: 3;
      width: calc(100vw * (448 / 1728));
      height: calc(100vw * (448 / 1728));
      pointer-events: none;
      min-width: 30rem;
      min-height: 30rem;
      &-inner {
        height: 100%;
        width: 100%;
        animation: Spinner 20s linear infinite;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          filter: brightness(0) saturate(100%) invert(60%) sepia(0%)
            saturate(2253%) hue-rotate(194deg) brightness(81%) contrast(89%);
        }
      }
    }

    @include max(800px) {
      padding: 4rem 2rem 2rem 2rem;
    }

    &-box {
      width: 58.8rem;
      border-radius: 0;
      background-color: $color-white;
    }

    .form {
      &-list {
        @include min(1201px) {
          --gutter-y: 3rem;
        }
      }
    }

    .bd-ln {
      margin-top: 4rem;
    }
  }
  &-cart {
    z-index: 100;

    .title {
      font-size: 2.8rem;
      margin-bottom: 2rem;
    }

    .popup {
      &-over {
        overflow-y: unset;
        min-height: unset;
      }
      &-main {
        width: 43rem;
        padding: 4rem;

        @include max(992px) {
          padding: 2.4rem;
        }
      }
      &-close {
        width: 4.4rem;
        height: 4.4rem;
        background-color: $color-pri;
        top: 0;
        right: 0;
        i {
          color: white;
        }
      }
    }
    &-bot {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      margin-top: 2.4rem;

      .btn {
        width: 100%;
      }
    }
    &.open {
        .popup-main {
          opacity: 1;
          visibility: visible;
        }
      }
  }
  &-attri {
    .popup-over {
      min-height: fit-content;
    }

    .recheck {
      margin-top: 0.8rem;
    }
    .size {
      .recheck {
        &-block {
          display: flex;
          gap: 0.8rem;
          flex-wrap: wrap;
        }
        &-text {
          margin: 0;
          padding: 0.5rem 1rem;
          border: 0.1rem solid rgba(26, 26, 26, 0.4);
          color: rgba(26, 26, 26, 0.4);
          font-weight: 600;
        }
        &-item {
          &.active {
            .recheck-text {
              border-color: rgba(26, 26, 26, 1);
              color: rgba(26, 26, 26, 1);
            }
          }
        }
      }
    }
    &-item {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.open {
      .popup-main {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &-sz {
 
    .popup {
      &-main {
        padding: 0;
        width: 74.5rem;

        @include max(768px) {
          width:max-content;
        }
      }
      &-close {
        right: 0;
        top: 0;
        width: 4.4rem;
        height: 4.4rem;
        background-color: $color-pri;

        i {
          color: white;
        }
      }
    }
    &-flex {
      display: flex;
    }
    &-left {
      width: 50%;
      padding: 4rem;

      @include max(992px) {
        padding:4rem 2rem;
      }
      @include max(768px) {
        width: 100%;
      }
    }
    &-right {
      width: 50%;

      @include max(768px) {
        display: none;
      }
    }
    &-img {
      height: 100%;
      width: 100%;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-title {
      @include fz-45;
      line-height: 1.2;
    }
    table {
      width: 100%;
      margin-top: 2rem;
      thead {
        th {
          border: 0.1rem solid $color-pri;
          padding: 1rem 1.6rem;
          text-align:left;
        }
      }
      tbody {
        tr {
          border: 0.1rem solid $color-pri;


          td {
          padding: 1rem 1.6rem;

            &:nth-child(1) {

              border-right: 0.1rem solid $color-pri;

            }
          }
        }
      }
    }
  }
  &-zoom {
    @include max(992px) {
      display: none;
    }
    .popup {
      &-main {
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
      }
      &-over {
        max-height: calc(100vh - 2.4rem);
      }
      &-close {
        right: 1rem;
        top: 1rem;
        width: 4.4rem;
        height: 4.4rem;
        background-color: $color-pri;

        i {
          color: white;
        }
      }
    }
    &-slide {
      width: 40%;
      margin: 0 auto;

      .swiper{
        max-height: calc(100vh - 2.4rem);
      }
    }
    &-img {
      width: 100%;
      padding-top: 100%;
      overflow: hidden;
      display: block;

      img {
        @include imgCover;
      }
    }
    &-thumb {
      width: 10rem;
      position: absolute;
      left: 4rem;
      top: 50%;
      transform:translateY(-50%);

      .swiper {
        max-height: 95vh;
        &-wrapper{

        }
        &-slide {
          height:10rem !important;
        }
      }
    }
    &-timg {
        width: 100%;
        height: 100%; 

        img {
          width: 100%;
          height: 100%; 
          object-fit: cover;
        }
    }
  }
  &-changepass {
    .popup {
      &-main {
        width: 48rem;
        padding: 4rem;

        @include max(500px) {
          padding: 4rem 2rem;
        }
      }
      &-over {
        min-height: unset;
      }
      &-close {
        right: 0;
        top: 0;
        width: 4.4rem;
        height: 4.4rem;
        background-color: $color-pri;

        i {
          color: white;
        }
      }
    }
    &-form {
      margin-top: 2rem;

      .ip-control {
        margin-bottom: 1.6rem;
      }
    }
    &-link {
      color: #474140;
      font-weight: 500;
      margin-bottom:1.6rem;
      display: flex;
      flex-wrap: wrap;

      .link {
        text-decoration: underline;
        transition:0.3s ease-in-out;

        @include hover {
          color: $color-pri;
        }
      }
    }
  }
  &-down {

    .title {
      font-size: 3.6rem;
      margin-bottom: 2rem;
      line-height: 1.2;
    }
    
    .popup {
      &-over {
        overflow-y: unset;
      }
      &-main {
        width: 48rem;
        padding: 4rem;

        @include max(768px) {
          padding:4rem 2rem;
        }
      }
      &-close {
        width: 4.4rem;
        height: 4.4rem;
        background-color: $color-pri;
        top: 0;
        right: 0;
        i {
          color: white;
        }
      }
    }

    &-form {
      .ip-control {
        margin-bottom: 1.6rem;
        input,textarea {
          width: 100%;
          height: 100%;
          border: 1px solid #f3edea;
          background: #f9f7f5;
          font-size: 1.4rem;
          padding: 0 1.2rem;


          &:focus {
            outline: none;
            border-color: black;
          }
        }
        input {
          height: 4rem;
        }
        textarea {
          min-height: 10rem;
          padding: 1.2rem;
        }
      
      }
    }

  }
  &-welcome {
    .popup {
      &-main {
        padding: 0;
        width: 78rem;

        @include max(768px) {
          width:max-content;
        }
      }
      &-close {
        right: 0;
        top: 0;
        width: 4.4rem;
        height: 4.4rem;
        background-color: $color-pri;

        i {
          color: white;
        }
      }
    }
    &-flex {
      display: flex;
    }
    &-left {
      width: 50%;
      padding: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include max(992px) {
        padding:4rem 2rem;
      }
      @include max(768px) {
        width: 100%;
      }

      .title {
        font-size:12rem;
        font-weight: 700;
        line-height: 1;
      }
      .sub {
        font-size:2.8rem;
        font-weight: 600;
      }
      .desc {
        text-align: center;
        margin-top: 2rem;
      }
      .support-form {
        input {
          width: unset;
        }
        @include max(500px) {
          input {
            width: 100%;
          }
          .btn {
            width: 100%;
          }
        }
      }
    }
    &-right {
      width: 50%;

      @include max(768px) {
        display: none;
      }
    }
    &-logo {
      width: 5.6rem;
      margin-bottom: 2rem;
      img {
        width:100%;
        object-fit: contain;
      }
    }
    &-img {
      height: 100%;
      width: 100%;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-control {
      width: 100%;
    }
  }
  &-acc {
    .popup {
      &-main {
        padding: 0;
        width: 70rem;
      }
      &-close {
        right: 0;
        top: 0;
        width: 4.4rem;
        height: 4.4rem;
        background-color: $color-pri;

        i {
          color: white;
        }
      }
      &-acc {
        &-img {
          width: 100%;height: 100%;
          display: flex;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @include max(700px) {
            padding-top:calc((190/321) * 100%);
            position: relative;
            overflow: hidden;

            img {
              @include imgCover;
            }
          }
        }
      }
    }
    &-flex {
      display: flex;

      @include max(700px) {
        flex-direction:column-reverse;
      }
    }
    &-left {
      width: 58%;
      padding: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include max(768px) {
        padding: 4rem 2rem;
      }
      @include max(700px) {
        width: 100%
      }
      .title {
        @include fz-45;
        line-height: 1.2;
      }
      .txt {

      }
      .link {
        text-decoration: underline;
        font-weight:500;
        color: #474140;
        margin-top: 1.5rem;
        
      }
    }
    &-right {
      flex: 1;
    }
  }
}

.cmini {
  &-ctn {
    margin-right: -0.5rem;
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    max-height: 20rem;
    overflow-y: auto;
    padding-right: 0.5rem;
  }
  &-item {
    display: flex;
    gap: 1.6rem;
  }
  &-img {
    width: 10rem;
    height: 10rem;
  }
  &-desc {
    flex: 1;
    display: flex;
    height: auto;
    justify-content: space-between;
    flex-direction: column;
    gap: 0.5rem;

    &-bot {
      display: flex;
      justify-content: space-between;
    }
  }
  &-name {
  }
  &-quan {
    border: 0.1rem solid black;
    width: fit-content;
    height: fit-content;
    padding: 0 1rem;
  }
}
