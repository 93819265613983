//general css
.no-scroll {
  overflow-y: hidden !important;
}
.event-none {
  pointer-events: none;
}
.f-title {
  font-family: 'Alumni Sans', sans-serif;
}
.t-end {
  text-align: end;
}
.o-hidden {
  overflow: hidden;
}
.c-pri {
  color: $color-pri;
}
.c-four {
  color: $color-four;
}
.c-five {
  color: $color-five;
}
.c-text {
  color: $color-text;
}
.c-white {
  color:$color-white;
}
.c-black {
  color:$color-black;
}
.c-grey {
  color: $color-grey;
}
.c-red {
  color: #E52346;
}
.c-blue {
  color: #1454D3;
}
.c-green {
  color:#0BAE62;
}
.fw-4 {
  font-weight: 400;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.j-beweent {
  justify-content: space-between;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.d-none {
  display: none;
}
.c-gap-1 {
  column-gap: 1rem;
}
.btnc {
  cursor: pointer;
}
.btn.trans {
  border: 0.1rem solid $color-pri;
}


.mt-24 {
  margin-top:2.4rem;
}
.mt-50 {
  margin-top: 5rem;

  @include max(768px) {
    margin-top: 3rem;
  }
}
.m-auto {
  margin: 0 auto;
}
.mt-10 {
  margin-top: 1rem;
}
.mt-12 {
  margin-top: 1.2rem;
}
.mt-15 {
  margin-top: 1.5rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-30 {
  margin-top: 3rem;
}
.mt-40 {
  margin-top: 4rem;

  @include max(768px) {
    margin-top: 2rem;
  }
}
.mt-60 {
  margin-top: 6rem;
  @include max(768px) {
    margin-top: 3rem;
  }
}
.mt-80 {
  margin-top: 8rem;
  @include max(1024px) {
    margin-top: 4rem;
  }
}
.mt-100 {
  margin-top: 10rem;
  @include max(1024px) {
    margin-top: 6rem;
  }
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.mb-50 {
  margin-bottom: 5rem;
}
.mb-60 {
  margin-bottom: 6rem;

  @include max(768px) {
    margin-bottom: 3rem;
  }
}
.mb-100 {
  margin-bottom: 10rem;
  @include max(768px) {
    margin-bottom: 5rem;
  }
}
.pt-30 {
  padding-top: 3rem;
}
.pt-10 {
  padding-top: 1rem;
}
.pb-200 {
  padding-bottom: 20rem;
}
.pb-250 {
  padding-bottom: 25rem;

  @include max(768px) {
    padding-bottom: 20rem;
  }
}
.sec-py {
  padding: 10rem 0;

  @include max(1300px) {
    padding: 8rem 0;
  }

  @include max(1024px) {
    padding: 6rem 0;
  }

  @include max(768px) {
    padding: 4rem 0;
  }
}

a.under {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0.1rem;
    width: 0;
    transition: 0.3s ease-in-out;
    background-color: black;
  }
  @include hover{
    color: black;
    &:before {
      width: 100%;
    }
  }
}

.btn {
  &.bg-sec {
    background-color: $color-second;
    transition: 0.3s ease-in-out;
    .text {
      color: $color-pri;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      .text {
        color: #fff;
      }
    }
  }
}
.t36 {
  font-size: 3.6rem;

  @include max(1200px) {
    font-size: 2.8rem;
  }
}
.t52 {
  font-size: 5.2rem;
  @include max(1200px) {
    font-size: 3.5rem;
  }
}
.t64 {
  font-size: 6.4rem;

  @include max(1200px) {
    font-size: 4.8rem;
  }
}

.none-events {
  pointer-events: none;
}

.hv-item {
  position: relative;
  overflow: hidden;
}
@media (hover: hover) {
  .hv-item:hover::before {
    -webkit-animation: shine 1.25s;
    animation: shine 1.25s;
  }
}
.hv-item::before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.ip-control {
  width: 100%;
  position: relative;

  &.x2 {
    width: 50%;
  }
  &.x3 {
    width: calc(100% / 3);
  }
}
.pro-price,
.prddt-prd-price-box {
  .info-price-current {
    gap: 0.8rem;
    display: flex;
    flex-direction: row-reverse;
    del {
      font-size: 1.2rem;
      color: #807d7c;
      display: flex;
      align-items: center;
    }
    ins {
      text-decoration: none;
      font-weight: 500;
      color: #474140;
      .woocommerce-Price-amount {

      }
    }
  }
}
.pdp-dk-name {
  text-align: center;
}
.pdp-dk-price {
  .info-price-current {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    del {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.2rem;
      color: #807D7C;
    }
    ins {
      text-decoration: none;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}
.pdp-price {
  
}
.prddt-prd-price-box-sale {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}

.acc {
  .pro-item .recheck-checkbox {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }
  .pro-item .recheck-item.active .recheck-checkbox {
    border-color: #000;
  }
}

.empty-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 5rem 0;

  .image-empty-product {
    display: flex;
    width: 30%;

    @include max(450px) {
      width: 40%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .text {
    text-align: center;
  }
  .btn {
    margin-top:2rem;
  }
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  display:flex !important;
}

.cartod-form #billing_address_2_field>label,.cartod-form #shipping_address_2_field>label {
  display:none !important;
}
.cartod-form {
  #billing_last_name_field {
    display:none;
  }
  .form-list {
    margin: 0 -0.6rem;
  }
  .form-ip,
  .form-row {
    padding: 0 0.6rem !important;
    margin: 0 !important;
    margin-bottom: 1.2rem !important;
    label {
      display:none !important;
    }
    input {
      border: solid 1px #e1e1e1 !important;
    }

    #billing_address_2_field {
      label {
        display:none !important;
      }
    }
  }

  #billing_country_field {
    display:none !important;
  }

  #billing_email_field {
    order: 1 !important;
  }
  #billing_first_name_field {
    order: 2 !important; 
    width: 50%;
  }
  #billing_phone_field {
    order: 3 !important;

  }
  #billing_address_1_field {
    order: 4 !important;
  }
  #order_comments_field {
    order: 9 !important;
  }

  @include max(992px) {
    #billing_state_field,
    #billing_city_field,
    #billing_address_2_field {
      width: 100% !important;
    }
  }
  @include max(400px) {
    #billing_first_name_field,
    #billing_phone_field {
      width: 100% !important;
    }
  }
}

.pcart-total {
  .pcart-total-flex {
     ul#shipping_method li input {
      appearance: auto;
      accent-color: #000;
      cursor: pointer;

    }
  }
}

.pdp-op {
  .check-stock {
    margin-top: 1rem;
    .text {
      color: #f63e15;
      background: #ffded6;
      @include mid-flex;
      padding: 0.5rem 1rem;
      width: max-content;
      font-weight: 500;
    }
  }
}

.empty-product {
  width: 50%;
  margin: 0 auto;
  @include max(992px) {
    width: 80%;
  }
  @include max(650px) {
    width: 100%; 
  }
}
.empty-product .image-empty-product {
  width: 20%;
}

.popup-cart {
  .popup-cart-bot {
    .btn-second.popup-close {
      background: none;
    }
  }
  .container {
    padding: 0;
  }
  .empty-product {
    width: 100%;
  }
}