.popup {
    &-login {
        .popup-main {
            box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.10);
            right: 0;
            top: var(--size-hd);
            left:unset;
            position: absolute;
            max-width: 30rem;
            transform: translateY(1rem);
            opacity: 0;
            
        }
        .popup-over {
            min-height: fit-content;
            display: flex;
            flex-direction: column;
            // align-items: center;

            .btn {
                margin:  0 auto;
                margin-top: 2.4rem;
            }

            .desc {
                text-align: center;
                margin-top:1.2rem;

                .link {
                    text-decoration: underline;
                    margin-left: 0.4rem;
                }
            }
        }
        .popup-overlay {
            background: none;
        }
        .t28 {
            font-size:2.8rem; 
        }
        &-list {
            display: flex;
            flex-direction: column;
            margin-top: 1.6rem;
        }
        &-item {
            padding: 0.6rem 0;
            font-weight: 500;
        }
        &.open {
            .popup-main { 
                transform: translate(0); 
                opacity: 1;
            }
        }
    }
}

.plogin {
    padding: 6rem 0;
    @include max(992px) {
        padding: 4rem 0;
    }
    &-inner {
        width: calc((7/12) * 100%);
        margin: 0 auto;

        @include max(1200px) {
            width: 85%;
        }
        @include max(992px){
            width: 100%;
        }
    }
    &-flex {
        display: flex; 
        margin: 0 -3rem;

        @include max(1200px) {
            margin: 0 -1rem;
        }
    }
    &-left {
        padding: 0 3rem;
        width: calc((3/7) * 100%);
        @include max(1200px) {
            padding: 0 1rem;
        }
        @include max(768px) {
            display: none;
        }
    }
    &-right {
        padding: 0 3rem;
        width: calc((4/7) * 100%);

        @include max(1200px) {
            padding: 0 1rem;
        }
        @include max(768px) {
            width: 100%;
        }
    }
    &-img {
        .box {
            position: relative;
            overflow: hidden;
            padding-top: calc((426/320) * 100%);

            img {
                @include imgCover;
            }
        }
    }
    &-title {
        display: flex;
        align-items:center;
        margin-bottom: 0.8rem;

        .icon {
            display: flex;
            align-items:center;
            width: 4rem;

            img {
                width: 100%;
                object-fit: contain
            }

        }
        .text {
            font-size: 3.6rem;
            font-weight: 600;
            line-height: 1;

            @include max(350px) {
                font-size:3.2rem;
            } 
        }
    }
    &-desc{
        font-weight:500;
        margin-top: 0.4rem;
    }
    &-form {
        &-inner {
            margin-top: 2.4rem;

            .btn {
                width: 100%;
                margin-top: 1.6rem;
            }
        }
        &-hear {

        }
        &-policy {
            margin-top: 2.4rem;
            .text {
                color: #807D7C;
                @include fz-12;

                .link {
                    transition:0.3s ease-in-out;
                    text-decoration: underline;

                    @include hover {
                        color: black;
                    }
                }
            }
        }
        &-bot {
            margin-top: 1.5rem;
            .link {
                margin-left: 0.5rem ;
                text-decoration: underline;
            }
        }
    }

    .ip-control {
        height: 4.8rem;
        position: relative;
        margin-bottom: 1.6rem;

        .text-abs {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.4s;
            pointer-events: none;
            z-index: 2;
            left: 1.2rem;
            line-height: 1;
        }
        input {
            outline: none;
            width: 100%;
            border: 1px solid  #F3EDEA;
            transition: 0.3s;
            height: 4.8rem;
            padding:0 1.2rem;
            font-weight: 500;
            background: #F9F7F5;


        }
        &.active {
            .text-abs {
                transform:translateY(0);
                top: 0.5rem;
                font-size: 1rem;
            }
            input {
                padding-top: 1.5rem;
            }
        }

        .text-show {
            position:absolute;
            top: 50%;
            transform:translateY(-50%);
            right: 1.2rem;
            cursor: pointer;

            .text {
                text-decoration: underline;
                font-weight: 500;


                &:nth-child(2) {
                    display: none;
                }
            }

            &.show {
                .text {
                    &:nth-child(1) {
                        display:none;
                    }
                    &:nth-child(2) {
                        display: block;
                    }
                }
            }
        }
    }

    .recheck {
        margin-top: 0.8rem;
        &-block {
            display:flex;
            gap: 2.4rem;
        }
        &-checkbox {
            height: 1.6rem;
            width: 1.6rem;
            border: 1px solid $color-grey-3;

            &::before {
                font-weight: 700;
            }
        }
        &-item {
            &.active {
                .recheck-checkbox {
                    background:$color-second;
                    border-color: $color-second ;
                }
            }
        }
    }

    &.signin {
        .plogin-form-bot {
            display: flex;
            justify-content:space-between;
            gap:1rem;
        }
    }
}

.spo {
    &-wrap {
        padding: 3.8rem 0;
        border-top: 1px solid  $color-pri;
        border-bottom: 1px solid  $color-pri;
        background:  #ECEBEA;
    }
    &-item {
        display: flex;
        align-items: center;
        gap: 0.4rem;

        .icon {
            display: flex;
            width: 2.4rem;
            align-items: center;

            img {
                width: 100%;
                object-fit: contain;
            }
        }
        .text {
            line-height:1;
            flex: 1;
            font-weight: 600;
            white-space: nowrap;
        }
    }
    .swiper {
        &-wrapper {
            transition-timing-function: linear !important;
        }
        &-slide {
            width: max-content;
        }
    }
}