.breadcrumb {
  max-width: 100%;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 0.2rem;
  }
  &-inner {
      width: fit-content;

      @include max(600px) {
        border-radius:0;
        border: none;
        padding: 0;
      }
  }
  &-list {
    @include flex;
    flex-wrap: wrap;
    row-gap: 0.5rem
  }
  &-item {
    @include flex;
    align-items: center;
    // width: max-content;
    margin-right: 0.5rem;
    white-space: wrap;
    position: relative;

    &:before {
      position: absolute;
      content:"/";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.2rem;

      display: none;
    }
  
    &:not(:first-child) {
      column-gap: 0.5rem;
      padding-left: 1rem;

      &:before {
        display: block;
      }
    }
    &:last-child {
      i,
      .breadcrumb-link {
        font-weight: 500;

      }
    }
    &:first-child {
      i {
        display: none;
      }
    }

    &:nth-child(2) {
      transition-delay: 0.4s !important;
    }
    &:nth-child(3) {
      transition-delay: 0.6s !important;
    }
    &:nth-child(4) {
      transition-delay: 0.8s !important;
    }
    &:nth-child(5) {
      transition-delay: 1s !important;
    }
  }
  &-link {
    @include fz-14;
    line-height: 1;
    color: #807D7C;
    font-weight: 500;

  }
}
.breadcrumb-page {
  margin-top: 1rem;
  @include max(1200px) {
    padding-top: 0;
    margin-top: 2.4rem;
  }
  .breadcrumb-list {
    justify-content: unset;
  }
  .breadcrumb-item {
    &::after {
      color: $color-text;
    }
  }
  .breadcrumb-link {
    color: rgba(30, 30, 30, 0.5);
    
  }
}