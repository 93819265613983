.header {
  height: var(--size-hd);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 99;
  background: white;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   @include full;
  //   backdrop-filter: blur(2.5rem);
  //   z-index: -1;
  //   @include max(1200px) {
  //     backdrop-filter: unset;
  //   }
  // }

  &-wrapper {
  background: white;
    position: relative;
    z-index: 98;
  border-bottom: 0.1rem solid $color-pri;
    height: inherit;
  }

  &-logo {
    padding-right: 2.6rem;
    height: inherit;
    @include mid-flex;

    @include max(1200px) {
      display: none;
    }

    &-mobile {
      display:none;
      position: relative;
      @include max(1200px) {
        display: block;

        .custom-logo-link {
          width: 10rem !important;
        }
      }
    }
    @include max(1200px) {
      border-right: unset;
      padding: unset;
      position: absolute;
      width: max-content;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @include max(768px) {
      padding-right: 5.6rem;
    }
  }
  .custom-logo-link {
    display: block;
    @include transitionRe;
    width: 6rem;
    
    img {
      display: block;
      max-width: 100%;
      height: auto;
      transition: 0.3s ease-in-out;
      width: 100%;
      object-fit: contain;
    }
  }
  &-wrap {
    @include flex;
    height: inherit;
    align-items: center;
    @include max(1200px) {
      justify-content: flex-start;
      width: 100%;
      gap: 1.2rem;
    }
  }
  &-top {
    display: flex;
    column-gap: 2.4rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    transition: 0.3s ease-in-out;

    &-search {
      flex: 1;

      .ip-control {
        input {
          background:  rgba(103, 103, 103, 0.1);
          border:none;
          font-size: 1.2rem;
          padding: 0 1.5rem;
          padding-right: 4rem;
          height: 3.2rem;
        }
        .icon {
          font-size: 1.6rem;
          font-weight: 500;
        }
      }


    }
    
    &-lang {
      display: flex;
      column-gap:2.4rem;
      position: relative;
      .icon {
        display: flex;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 100rem;
        position: relative;
        padding: 0.2rem;
        cursor: pointer;


        &.active {
          &:before { 
            width: 100%;
            height: 100%;
            position: absolute;
            content:"";
            border: 0.1rem solid $color-pri;
            border-radius: 100rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }

        &:first-child {
          &:after {
            position: absolute;
            content: "";
            height: 100%;
            width: 0.2rem;
            background-color: $color-pri;
            top: 0;
            left:calc(100% + 1.2rem)

          }
        }


        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  &-gr {
    @include flex;
    flex: 1;
    gap: 0.8rem;
    height: inherit;
    align-items: center;
    @include max(1200px) {
      flex: unset;
      margin-left: auto;
    }
  }
  &-nav {
      margin: 0 auto;

      .menu {
          height: inherit;
      }
  }
  &-action {
    @include flex;
    gap: 0.8rem;
    align-items: center;
    height: inherit;

    @include max(1200px) {
      
      &.phone {
        display: none;
      }
    }

    &-phone {
      border-radius: 100rem;

      .phone-flex {
        display: none;
      }

      @include  min(1500px) {
        .phone-flex {
          display: flex;
          flex-direction: column;
          position: relative;
          padding-bottom: 3rem;

          .num-phone {
            position: absolute;
            left: 0;
            bottom: 0;
            width: max-content;
          }
        }
        .icon {
          display:none !important;
        }
      }

 

      @include hover {
        .icon {
          -webkit-animation: shake-lr 1.4s linear infinite both;
          animation: shake-lr 1.4s linear infinite both;
        }
      }
    }

    @include max(1200px) {
      &.hambuger {
        position: absolute;
        left: 1.5rem;height: 5rem;
        width: 5rem;
      }
    }

    @include max(768px) {
      gap: 0.4rem;
    }
    &-icon {
      @include mid-flex;
      position: relative;
      width: auto;
      // aspect-ratio: 1;
      cursor: pointer;

      .icon {
        position: relative;

        .num-quan {
          position: absolute;
          top: -0.5rem;
          right: -0.5rem;
          width: 1.8rem;
          height: 1.8rem;
          border-radius:50%;
          @include mid-flex;
          color: white;
          @include fz-10;
          line-height: 1;
          padding-top: 0.2rem;
        }
      }

      .text {
        font-weight: 600;
        line-height: 1;
      }

      &.side-open {
        .icon {
          position: relative;

          .num-quan {
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            width: 1.8rem;
            height: 1.8rem;
            border-radius:50%;
            @include mid-flex;
            color: white;
            @include fz-10;
            line-height: 1;
            padding-top: 0.2rem;
          }
        }
      }
    }
    .icon {
      @include mid-flex;
      width: 2.4rem;
      height: 2.4rem;
      flex-shrink: 0;
      transition: 0.3s ease-in-out;
      color: #444545;
      
      img {
        width: 100%;height: 100%;
        object-fit: contain;
      }
      @include max(1200px) {
        width: 2.4rem;height: 2.4rem;
      }
      @include hover {
        color: $color-four;
      }
    }
  }
  &-control {
    @include flex;
    gap: 1.2rem;
    align-items: center;
    height: inherit;
    padding: 1.2rem 0 ;
    transition: 0.3s ease-in-out;
    @include max(1200px) {
        padding: 0.5rem 0;
    }
    @include max(768px) {
      gap: 1.4rem;
    }
    @include max(440px) {
      gap: 1.4rem;
    }
  }
  &-btn {
    position: relative;
    .tnum {
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      border: 0.1rem solid rgba(254, 239, 232, 1);
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      @include mid-flex;
      top: 0;
      left: 100%;
      transform: translate(-50%, -50%);
      z-index: 1;
      padding-top: 0.2rem;
      
    }
    @include max(768px) {
      border: unset;
    }
    .btn.side-open {
      .btn-inner {
        padding: 0.6rem 1rem;
        border-radius: inherit;
        @include max(768px) {
          padding: unset;
          @include mid-flex;
          width: 3.6rem;
          height: 3.6rem;
          border-radius: 50%;
        }
        .text {
          font-weight: 500;
          font-size: 1.6rem;
          @include max(768px) {
            display: none;
          }
        }
      }
    }
  }

  &.show-form {
    .header-sform {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
  &-sform {
    background:white;
    border-top: 0.1rem solid black;
    border-bottom: 0.1rem solid black;
    transform: translateY(-110%);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 90;

    &-close {
      position: absolute;
      right: 4rem;
      top: 2rem;
      .text {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    

    &-inner {
      padding: 3rem 0;
      max-width: 80rem;
      margin: 0 auto;

      input {
        height:4.4rem;
        width:100%;
        border: 1px solid black;box-shadow: none;outline: none;
      }

      .btn {
        margin: 0 auto;
        margin-top:2rem;
      }
    }
  }
}




.header-icon-logged {
border-radius:100rem;
overflow: hidden;

img {
  width: 100%;height: 100%;
  object-fit: cover;
}
}
