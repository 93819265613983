// --------------------------------------
.mobile {
  position: fixed;
  z-index: 140;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 50rem;
  width: 50rem;
  background-color: white;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
  @include transition(0.4s, linear);
  transform: translateX(-110%);
  overflow: hidden;

  @include max($mobile) {
    width: 70%;
    min-width: initial;
  }
  @include max(440px) {
    width: 100%;
  }

  &.sticky {
    top: var(--size-hd-sticky);
  }

  &.open {
    transform: translateX(0);
  }

  @include min($res-mobile-min) {
    display: none;
  }

  &-con {
    width: 100%;
    height: 100%;
    @include flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 4.4rem 2rem;
  }

  &-heading {
    font-size: 1.8rem;
    line-height: 1.5;
    color: $color-pri;
    font-weight: 700;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  &-overlay {
    @include full-fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    @include transition(0.6s, ease);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-110%);
    border-top: 1px solid $color-border-01;
    top: 0;

    &.sticky {
      top: var(--size-hd-sticky);
    }

    &.open {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }

    @include min($res-mobile-min) {
      display: none;
    }
  }

  &-wr {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    @include flex;
    flex-direction: column;

    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.1rem;
      width: calc(100% + 6rem);
      background: $color-grey-3;
    }
    .header-search {
      display: block;
      padding: 1.2rem;
      padding-bottom: 0;
    }
  }

  &-contact {
    .menu-list {
      .menu-link {
        font-weight: 500;
        padding: 0.4rem 0;
        display: block;
      }
    }
  }

  &-list {
    margin-bottom: 2rem;
  }

  &-content {
    padding: 2.4rem 1.6rem;
  }

  &-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1.6rem;
  }

  &-lg {
    display: flex;
    margin-bottom: 1rem;
    padding: 0 1.2rem;
  }

  &-mxh {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    @include flex;
    align-items: center;
    border-top: 1px solid $color-border-01;

    &-link {
      @include mid-flex;
      flex: 1;
      height: 100%;
      background-color: white;
      cursor: pointer;

      &:not(:first-child) {
        border-left: 1px solid $color-border-01;
      }

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  &-text {
    @include fz-14;
    display: block;
    color: $color-pri;
    word-break: break-word;
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }

  &-account {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &-re {
    @include flex;
    align-items: center;

    &-link {
      @include fz-16;
      color: $color-pri;
      text-decoration: underline;

      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 4.4rem;
    height: 4.4rem;
    padding: 1.5rem;
    @include mid-flex;
    cursor: pointer;
    background-color: #1a1a1a;
    z-index: 5;

    img {
      filter: brightness(0) invert(1);
    }

    .icon {
      transition: 0.6s ease-in-out;
      font-weight: 500;
      font-size: 1.8rem;
      color: $color-pri;
    }
  }

  &-fl {
    .fw-6 {
      margin-bottom: 0.8rem;
      margin-bottom: 0.8rem;
      display: inline;
      width: fit-content;
      font-size: 1.4rem;
    }

    .mobile-text {
      display: inline;
      font-size: 1.4rem;
    }

    .dot {
      display: none;
    }
  }
  &-if {
    padding: 1.2rem;
    order: 2;
    &-list {
      @include flex;
      flex-direction: column;
      gap: 1.6rem;
    }
    &-gr {
      @include flex;
      align-items: center;
      gap: 0.8rem;
      .icon {
        @include mid-flex;
        width: 4rem;
        height: 4rem;
        aspect-ratio: 1;
        flex-shrink: 0;
        border-radius: 100rem;
        border: 0.1rem solid $color-pri;

        i {
          color: $color-pri;
        }
      }
      .text {
        font-size: 1.5rem;
        color: $color-text;
      }
    }
  }

  .menu-list {
    margin-bottom: 1rem;
  }

  &-sub {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transform: translateX(110%);
    transition: 0.3s ease-in-out;
    padding-top: 4.4rem;

    &.showed {
      transform: translateX(0);
    }

    &-ctn {
      padding-bottom: 6rem;
      padding-top: 1rem;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
    }

    &-pre {
      position: absolute;
      left: 0;
      top: 0;
      padding: 1rem 1.6rem;
      display: flex;
      align-items: center;
      gap: 0.8rem;
      height: 4.4rem;
      z-index: 4;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100vw;
        height: 100%;
        background: white;
        z-index: 1;
      }

      .icon {
        position: relative;
        z-index: 4;
      }
      .text {
        font-size: 2.8rem;
        font-weight: 600;
        position: relative;
        z-index: 4;
      }
    }

    .mega-dh-list,
    .mega-ts-list {
      flex-wrap: nowrap;
      overflow-x: auto;
      margin: 0 -0.4rem;
      padding-left: 1.6rem;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    .mega-dh-item,
    .mega-ts-item {
      min-width: calc(100% / 3.5);
      padding: 0 0.4rem;
    }
    .mega-dh-name,
    .mega-ts-name,
    .mega-ddh-name {
      font-size: 1.2rem;
    }
    .mega-ts-name,
    .mega-ddh-name {
      margin-top: 0.8rem;
    }

    .mega-bs-flex {
      flex-direction: column;
      row-gap: 1rem;
    }
    .mega-bs-left {
      width: 100%;
    }
    .mega-bs-right {
      width: 70%;
      margin: 0 auto;
    }

    .mega-qt-list {
      flex-wrap: wrap;
      row-gap: 2rem;
      padding:0 1.6rem;
    }
    .mega-qt-item {
      width: 50%;
    }

    &-main {
      padding-bottom: 2rem;

      >.swiper {
        padding-left: 1.6rem;
      }
    }

    &-item {
      border-bottom: 0.1rem solid $color-grey-3;

      &:not(:first-child) {
        .mobile-sub-main {
          display: none;
        }
      }
      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2rem 1.6rem;
        > .text {
          font-size: 28px;
          font-weight: 600;
          font-family: "Alumni Sans", sans-serif;
        }
      }

      @include max(1200px) {
        .pro-item {
          .pro-add-pc {
            display: none;
          }
          .pro-add-mb {
            display: flex;
          }
        }
      }
    }
    &-link {
      text-decoration: underline;
      font-weight: 500;
    }
  }
}
