.bban {
  &-wrap {
    padding: 4rem 0;
  }
  &-inner {
    min-height: calc(100vh - var(--size-hd) - 4rem);
    display: flex;
    flex-direction: column;
  }
  &-top {
    margin-bottom: 2rem;
    &-flex {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      @include max(768px) {
        flex-direction: column;
        gap: 0;
      }
    }
    &-left {
      flex: 1;
    }
    &-right {
      width: calc((5 / 12) * 100%);
      display: flex;
      align-items: center;
      @include max(1400px) {
        width: calc((6 / 12) * 100%);
      }
      @include max(1200px) {
        width: unset;
      }
      @include max(768px) {
        width: 100%;
      }
    }
    .title {
      font-size: 12rem;
      line-height: 0.9;
      white-space: nowrap;

      @include max(1200px) {
        font-size: 10rem;
      }
      @include max(768px) {
        font-size: 6rem;
        line-height: 1;
      }
    }
  }
  &-slide {
    flex: 1;
    display: flex;
  }
  &-item {
    position: relative;
    height: 100%;

    &-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      z-index: -2;
      transition: 0.3s ease-in-out;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-flex {
    padding: 4rem;
    display: flex;
    justify-content: space-between;
    min-height: calc((600 / 1440) * 100vw);
    height: 100%;

    @include min(1600px) {
      min-height: unset;
    }
    @include max(768px) {
      padding: 2rem;
    }
  }
  &-left {
    display: flex;
    width: calc((5 / 12) * 100%);
    align-items: flex-end;

    @include max(1200px) {
      width: 60%;

      .title {
        font-size: 1.8rem !important;
      }
    }
    @include max(650px) {
      width: 100%;
    }
    .title {
      @include fz-24;
      color: white;
      font-weight: 600;
    }
    .text {
      color: #f3edea;
      font-weight: 500;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      color: #f3edea;
      font-weight: 500;
    }
  }
  &-right {
    display: flex;
    align-items: flex-end;
  }
  &-text {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    transition: 0.8s ease-in-out;
  }
  &-pagi {
    position: absolute;
    right: 4rem;
    top: 4rem;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .num {
      font-weight: 600;
      color: white;
      font-size: 3.6rem;
    }

    .swiper {
      &-pagination {
        position: relative;
        margin: 0;
        inset: 0 !important;
        width: 4rem;
        height: 0.2rem;
        opacity: 0.5;

        &-progressbar {
          &-fill {
            background: white;
          }
        }
      }
    }
  }
  &-link {
    width: 12rem;
    height: 12rem;
    border-radius: 100rem;
    border: 0.2rem solid white;
    @include mid-flex;

    @include max(1200px) {
      width: 8rem;
      height: 8rem;
    }
    @include max(650px) {
      display: none;
    }

    i {
      font-size: 5rem;
      color: white;
      transform: rotate(-45deg);
      transition: 0.3s ease-in-out;
    }
    @include hover {
      i {
        transform: rotate(0);
      }
    }
  }

  .swiper {
    &-slide {
      &-active {
        // .bban-item-bg {
        //     opacity: 1;
        //     transform:scale(1);
        // }
        .bban-text {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }
}

.lpost {
  padding: 2rem 0;
  padding-bottom: 4rem;
  &-inner {
    > .title {
      font-size: 4.4rem;
      line-height: 1.2;
      margin-bottom: 2.4rem;

      @include max(768px) {
        font-size: 1.8rem;
      }
    }
  }
  &-flex {
    display: flex;
    margin: 0 -1rem;
    @include max(992px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  &-left {
    width: calc((7.5 / 12) * 100%);
    padding: 0 1rem;
    position: relative;
    @include max(992px) {
      width: 100%;
    }

    &-ctn {
      background: $color-grey-3;
      width: 100%;
      height: 100%;
      padding: 3.2rem;

      @include max(1200px) {
        padding: 2rem;
      }
      @include max(768px) {
        padding: 2.4rem 1.6rem;
      }
    }
  }
  &-right {
    padding: 0 1rem;
    flex: 1;
  }
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    .title {
      @include fz-24;
      width: 75%;
      line-height: 1.3;
    }

    @include max(500px) {
      margin-top: 1rem;
      margin-bottom: 28%;

      .title {
        width: 70%;
      }
    }
    @include max(440px) {
      margin-bottom: 25%;
    }
    @include max(424px) {
      margin-bottom: 20%;
    }
  }
  &-logo {
    height: 8rem;
    width: 8rem;
    background: white;
    border-radius: 100rem;
    @include mid-flex;
    padding: 1.2rem;

    @include max(1200px) {
      width: 7rem;
      height: 7rem;
    }
    @include max(500px) {
      display: none;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }
  &-main {
    display: flex;
    margin: 0 -1rem;
    @include max(768px) {
      margin: 0 -0.4rem;
    }
  }
  &-thumb {
    width: calc((2.08 / 7) * 100%);
    padding: 0 1rem;
    @include max(768px) {
      padding: 0 0.4rem;
    }
    @include max(500px) {
      position: absolute;
      right: 1.6rem;
      top: 2.4rem;
      width: 27%;
    }

    &-img {
      border-radius: 2rem;
      overflow: hidden;
      padding-top: calc((281 / 220) * 100%);
      position: relative;

      img {
        @include imgCover;
      }
    }
  }
  &-slide {
    width: calc((4.92 / 7) * 100%);
    padding: 0 1rem;
    @include max(768px) {
      padding: 0 0.4rem;
    }
    @include max(500px) {
      width: 100%;
    }

    &-img {
      position: relative;
      padding-top: calc((281 / 546) * 100%);
      overflow: hidden;
      border-radius: 2rem;

      img {
        @include imgCover;
      }

      .text {
        position: absolute;
        color: white;
        font-size: 3.6rem;
        font-weight: 600;
        z-index: 2;
        bottom: 2rem;
        left: 2.8rem;
        line-height: 1;

        @include max(768px) {
          left: 1.6rem;
        }
      }

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        inset: 0;
        z-index: 1;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0) 56.04%
        );
      }
    }
  }
  &-btn {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    gap: 0.8rem;
    right: 2.8rem;
    bottom: 2rem;
    @include max(768px) {
      right: 1.6rem;
    }
    &-next,
    &-prev {
      width: 4rem;
      height: 4rem;
      border-radius: 100rem;
      border: 0.1rem solid white;
      position: relative;
      margin: 0;
      inset: 0;

      &::after {
        display: none;
      }

      i {
        color: white;
        font-size: 1.8rem;
      }
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include max(992px) {
      flex-direction: row;
    }
    @include max(800px) {
      flex-direction: column;
    }
  }
  &-item {
    display: flex;
    gap: 1.2rem;
  }
  &-img {
    width: 44%;
    .box {
      display: block;
      position: relative;
      padding-top: calc((137 / 216) * 100%);
      overflow: hidden;

      img {
        @include imgCover;
        transition: 0.3s ease-in-out;
      }
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
    @include max(800px) {
      width: 18rem;
    }
    @include max(360px) {
      width: 16rem;
    }
  }
  &-desc {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
  }
  &-cate {
    color: #807d7c;
    @include fz-12;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    @include hover {
      color: $color-pri;
    }
  }
  &-name {
    @include fz-16;
    color: black;
    font-weight: 600;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @include max(600px) {
      -webkit-line-clamp: 3;
    }
  }
  &-time {
    @include fz-12;
    color: #807d7c;
    font-weight: 500;
  }
}

.blog {
  padding: 4rem 0;
  border-top: 0.1rem solid $color-pri;
  &-top {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
  }
  &-link {
    display: flex;
    gap: 1.2rem;
    flex-wrap: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      height: 3.9rem;
      border-radius: 2.4rem;
      border: 1px solid #e5ded8;
      @include mid-flex;
      font-weight: 600;
      line-height: 1;
      padding: 0.4rem 2rem;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      white-space: nowrap;

      &.active {
        background: #e5ded8;
      }

      @include hover {
        background: #e5ded8;
      }
    }
  }
  &-srch {
    @include max(768px) {
      display: none;
    }
    &-inner {
      position: relative;
      height: 4.4rem;
      width: 30rem;
      padding: 0 1rem;
      padding-left: 3rem;
      border-radius: 24px;
      border: 1px solid var(--Gray-3, #e5ded8);
      overflow: hidden;

      input {
        border: none;
        outline: none;
        box-shadow: none;
        height: 100%;
        width: 100%;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        display: flex;
      }
    }
  }
  &-ctn {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @include max(600px) {
      gap: 3rem;
    }
  }
  &-row {
    &-top {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 0;

      .title {
        font-size: 4.4rem;
        line-height: 1.2;
      }
      .link {
        font-size: 1.6rem;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }
  &-list {
    display: flex;
    margin: 0 -1rem;
    flex-wrap: wrap;
    row-gap: 2rem;

    @include max(1100px) {
      margin: 0 -0.5rem;
    }
  }
  &-item {
    width: calc(100% / 3);
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    @include max(1100px) {
      padding: 0 0.5rem;
    }
    @include max(768px) {
      width: 100%;
    }
  }
  &-img {
    .box {
      padding-top: calc((280 / 440) * 100%);
      position: relative;
      overflow: hidden;
      display: block;

      img {
        @include imgCover;
        transition: 0.3s ease-in-out;
      }
      @include hover {
        img {
          transform: translate(-50%, -50%) scale(1.05);
        }
      }

      @include max(600px) {
        padding-top: calc((280 / 343) * 100%);
      }
    }
  }
  &-desc {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  &-cate {
    @include fz-12;
    font-weight: 600;
    color: #807d7c;
  }
  &-name {
    color: #000000;
    font-weight: 600;
    font-size: 1.8rem;
  }
  &-text {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #807d7c;
    font-weight: 500;
  }
  &-time {
    @include fz-12;
    color: #807d7c;
    font-weight: 500;
  }
  &-child {
    .blog-item {
      @include max(992px) {
        width: 50%;
      }
      @include max(768px) {
        width: 100%;
      }
    }
  }
}

.support {
  background: #ecebea;
  border-top: 1px solid $color-pri;

  &-wrap {
    padding: 8rem 0;

    @include max(768px) {
      padding: 5rem 0;
    }
  }
  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.5rem;
    .title {
      font-size: 4.4rem;
      line-height: 1.2;
      @include max(768px) {
        font-size: 3.6rem;
      }
    }
    .desc {
      color: #807d7c;
    }
  }
  &-form {
    display: flex;
    gap: 0.8rem;
    margin-top: 2rem;

    input {
      width: 30rem;
      background: #f9f7f5;
      border: none;
      outline: none;
      padding: 0 1.2rem;
      height: 4.4rem;
    }

    .btn {
      min-width: 10rem;
    }
    @include max(500px) {
      flex-direction: column;
      width: 100%;
      input {
        width: 100%;
      }
      .btn {
        width: 100%;
      }
    }
  }
}

// blog child
.bcban {
  &-wrap {
    position: relative;
  }
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: -1;
    @include max(650px) {
      display: none;
    }

    &-mb {
      &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        content: "";
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 94.79%
        );
      }
      @include max(768px) {
        display: block !important;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-inner {
    min-height: calc((400 / 1440) * 100vw);
    padding: 4rem 0;
    display: flex;
    align-items: center;

    @include max(650px) {
      min-height: 40rem;
      align-items: flex-end;
    }
  }
  &-ctn {
    padding: 0 4rem;
    margin-left: auto;
    width: 47%;
    .title {
      font-size: 10rem;
      line-height: 1;
    }

    @include max(1100px) {
      width: 55%;
    }
    @include max(768px) {
      width: 65%;
    }
    @include max(650px) {
      width: 100%;
      padding: 0;
      .title {
        font-size: 6rem;
      }
    }
  }
}

// blog-detail
.bdban {
  &-top {
    padding: 4rem 0;

    .breadcrumb {
      margin-bottom: 2rem;
    }

    &-inner {
      flex-direction: column;
      @include mid-flex;
      width: 70%;
      margin: 0 auto;

      @include max(1200px) {
        width: 80%;
      }
      @include max(1100px) {
        width: 100%;
      }
    }

    .title {
      font-size: 7.4rem;
      line-height: 1;
      color: #030303;
      margin-bottom: 1rem;

      @include max(768px) {
        font-size: 4.8rem;
      }
    }

    &-bot {
      display: flex;
      align-items: center;
      color: #807d7c;
      margin-top: 2rem;
      gap: 2rem;

      @include max(440px) {
        flex-direction: column;
      }
    }
  }
  &-social {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    border-left: 0.1rem solid #807d7c;
    @include max(440px) {
      padding-left: 0;
      border: none;
    }

    &-list {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      margin-left: 1.2rem;
    }
    &-item {
      width: 2.4rem;
      height: 2.4rem;
      display: flex;

      img {
        width: 100%;
        object-fit: contain;
        transition: 0.3s ease-in-out;
      }
      @include hover {
        img {
          transform: translateY(-0.5rem);
        }
      }
    }
  }
  &-img {
    width: 100%;
    padding-top: calc((500 / 1440) * 100%);
    position: relative;
    overflow: hidden;
    @include max(650px) {
      display: none;
    }

    img {
      @include imgCover;
    }

    &-mb {
      padding-top: calc((320 / 375) * 100%);

      @include max(650px) {
        display: block !important;
      }
    }
  }
}

.dblog {
  &-inner {
    padding: 6rem 0;
  }
  &-flex {
    display: flex;
    margin: 0 -3rem;
    @include max(992px) {
      margin: 0 -1rem;
    }
    @include max(768px) {
      margin: 0;
    }
  }
  &-left {
    padding: 0 3rem;
    width: calc((8 / 12) * 100%);

    @include max(992px) {
      padding:0 1rem;
    }
    @include max(768px) {
      width: 100%;
      padding: 0;
    }
  }
  &-right {
    flex: 1;
    padding: 0 3rem;

    @include max(992px) {
      padding:0 1rem;
    }
    @include max(768px) {
      display: none;
    }
  }
  &-ctn {
    scroll-margin-block-start: var(--size-hd);
    .title {
      line-height: 1.2;
      font-size: 4.4rem;
      text-transform: uppercase;
      margin-bottom: 1.2rem;

      @include max(768px) {
        font-size: 3.6rem;
      }
    }
    .subtitle {
      line-height: 1.2;
      font-size: 3.6rem;
      text-transform: uppercase;
      margin-bottom: 1.2rem;
    }
    .text {
      margin-bottom: 1.6rem;
    }
    .text-box {
      font-weight: 600;
      @include fz-20;
      padding-left: 2rem;
      position: relative;
      margin-bottom: 1.6rem;

      &::before {
        position: absolute;
        content: "";
        background-color: #ecebea;
        width: 0.4rem;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
  &-img {
    margin-bottom: 2rem;
    .box {
      position: relative;
      overflow: hidden;
      padding-top: calc((480 / 720) * 100%);

      img {
        @include imgCover;
        transition: 0.4s ease-in-out;
      }

      @include hover {
        img {
          transform: translate(-50%, -50%) scale(1.05);
        }
      }
    }
    &-name {
      font-weight: 500;
      font-style: italic;
      color: #474140;
      display: flex;
      justify-content: center;
      margin: 0.8rem 0;
    }
  }
  &-stick {
    position:sticky;
    top: calc(var(--size-hd) + 1rem) ;
  }
  &-totop {
    display: flex;
    align-items: center;
    background-color: #ecebea;
    border-radius: 3.2rem;
    width: max-content;
    height: 3.4rem;
    padding: 0.2rem 1.2rem;
    margin: 0 auto;
    margin-top: 2rem;
    transition: 0.3s ease-in-out;

    .icon {
      display: flex;
      width: 1.6rem;
      i {
        color: #807d7c;
    transition: 0.3s ease-in-out;

      }
    }

    .text {
      color: #807d7c;
    transition: 0.3s ease-in-out;

    }
    @include hover{
      i,
      .text {
        color: $color-pri;
      }
    }
  }
  &-box {
    padding: 4rem;
    border: 0.1rem solid $color-pri;
    margin-bottom: 4rem;
    @include max(1200px) {
      padding:1.6rem;
    }

    &-flex {
      display: flex;
      margin: 0 -2rem;
      @include max(1100px) {
        margin: 0 -0.5rem;
      }
      @include max(650px) {
        flex-direction: column;
        gap: 2rem;
      }
    }
    &-left {
      padding: 0 2rem;
      width: 50%;
      @include max(1100px) {
        padding: 0 0.5rem;
      }
      @include max(650px) {
       width: 100%;
      }
    }
    &-right {
      padding: 0 2rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      @include max(1100px) {
        padding: 0 0.5rem;
      }
      @include max(650px) {
        padding-bottom: 3rem;
       }

      .btn {
        height: 3.2rem;
        .text {
          margin: 0;
        }
      }
    }

    &-img {
      .box {
        position: relative;
        padding-top: calc((360 / 300) * 100%);
        overflow: hidden;

        img {
          @include imgCover;
        }
      }
    }
  }
  &-link {
    width: 6rem;
    height: 6rem;
    border-radius: 100rem;
    border: 0.2rem solid $color-pri;
    @include mid-flex;
    position: absolute;
    right: 1rem;
    bottom: -1rem;

    @include max(1200px) {
      width: 5rem;
      height: 5rem;
      bottom:0;
      right: 2rem;
    }
    @include max(1100px) {
      right: 0;
    }


    i {
      font-size: 3rem;
      color: $color-pri;
      transform: rotate(-45deg);
      transition: 0.3s ease-in-out;
    }
    @include hover {
      i {
        transform: rotate(0);
      }
    }
  }
  &-loca {
    border: 0.1rem solid #e5ded8;
    margin: 4rem 0;
    margin-top: 2.4rem;
    &-flex {
      display: flex;
      margin: 0 -1rem;
      @include max(650px) {
        flex-direction: column;
        gap:2rem;
        margin: 0;
      }
    }
    &-left {
      padding: 0 1rem;
      width: 50%;
      @include max(650px) {
        width: 100%;
        padding: 0;
      }
    }
    &-right {
      padding: 0.5rem 1rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      @include max(650px) {
        padding: 2rem;
        padding-top: 0;
      }
    }
    &-ctn {
      .text {
        margin-bottom: 1.2rem;
      }
      .link-list {
        display: flex;
        margin: 0 -1rem;
        margin-bottom: 1.2rem;
        .link-item {
          padding: 0 1rem;
          border-right: 0.1rem solid $color-pri;
          line-height: 1;
          text-decoration: underline;
          font-weight: 600;

          &:last-child {
            border-right: none;
          }
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
    }
    &-item {
      display: flex;
      gap: 0.5rem;
      .icon {
        width: 1.6rem;
        flex-shrink: 0;
      }
      .text {
        flex: 1;
      }
    }
    &-img {
      height: 100%;
      width: 100%;

      @include max(650px) {
        padding-top:calc((500/343) * 100%);
        height: unset;
        position: relative;
        overflow: hidden;

        img {
          @include imgCover;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-contact {
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
  &-more {
    .link {
      font-weight: 500;
      color: #1454d3;
      text-decoration: underline;
      cursor: pointer;
      display: block;
      margin-bottom: 0.8rem;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      margin-top: 3rem;
    }
    &-item {
      background-color: #ecebea;
      border-radius: 2.4rem;
      @include mid-flex;
      line-height: 1;
      font-weight: 500;
      padding: 0.4rem 1.6rem;
      height: 3.7rem;
    }
  }
}

.reblog {
  padding: 8rem 0;
  border-top: 0.1rem solid $color-pri;
  @include max(768px) {
    padding: 6rem 0;
  }
  &-inner {
    .title {
      line-height: 1.2;
      font-size: 4.4rem;
      margin-bottom: 1.2rem;

      @include max(768px) {
        font-size: 3.6rem;
      }
    }
  }
  &-list {
    display: flex;
    margin: 0 -1rem;
    flex-wrap: wrap;
    row-gap: 2rem;

    @include max(1100px) {
      margin: 0 -0.5rem;
    }
  }
}

.policy {
  &-inner {
    padding: 6rem 0;
    width: 76.5%;
    margin:0 auto;

    @include max(1200px) {
      width:100%;
    }
  } 
  &-ctn {
    scroll-margin-block-start: var(--size-hd);
    .title {
      line-height: 1.2;
      font-size: 5.2rem;
      margin-bottom: 1.2rem;

      @include max(768px) {
        font-size: 3.6rem;
      }
    }
    .subtitle {
      line-height: 1.2;
      font-size: 3.6rem;
      margin-bottom: 1.2rem;
    }
    .sub {
      line-height: 1.2;
      font-size: 2.8rem;
      margin-bottom: 1.2rem;
    }
    .text {
      font-weight: 500;
    }

    ul {
      padding-left: 2rem;
      list-style: disc;

      li {
        font-weight: 500;
      }
    }

  }
  &-flex {
    display: flex;
    margin: 0 -3rem;
    @include max(992px) {
      margin: 0;
    }
  }
  &-left {
    padding: 0 3rem;
    width: calc((8 / 12) * 100%);

    @include max(992px) {
      width: 100%;
      padding:0;
    }
  }
  &-right {
    flex: 1;
    padding: 0 3rem;

    @include max(992px) {
      display: none;
    }
  }
}

.dblog {
  #ez-toc-container {
    background:white;
    padding: 2.4rem;
  }
}

.dblog-ctn {
  .mona-content p {
    font-weight:500;
  }
  .wp-block-image {
    width: 100%;

    figure {
      // position: relative;
      // overflow: hidden;
      // padding-top: calc((480 / 720) * 100%);
      // display: block;
      width: 100%;

      img {
        // @include imgCover;
        width: 100% !important;
        height: 100% !important;
        aspect-ratio: 720/480;
        object-fit: cover;
      }
    }
    .wp-element-caption {
      font-weight: 500;
      font-style: italic;
      color: #474140;
      display: flex;
      justify-content: center;
      margin: 0.8rem 0;

      strong {
      font-weight: 500;

      }
    }
  }
  .wp-block-heading {
    font-family: 'Alumni Sans', sans-serif;
    line-height: 1.2;
    font-size: 4.4rem;
    text-transform: uppercase;
    margin-bottom: 1.2rem;
  }


}
.ez-toc-title-container {
  font-weight: 500;
}
.ez-toc-link,
#ez-toc-container a  {
  font-weight: 500;
}