.btn {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    height: 4.4rem;
    width: fit-content;
    overflow: hidden;
    min-width: 15rem;
    padding: 1rem 2rem;
    @include mid-flex;


    img {
        position: relative;
        z-index: 3;
    }

    .icon {
        width: 2rem;
        height: 2rem;
        position: relative;
        z-index: 3;
        flex-shrink: 0;
        display: flex;
        align-items: center;

        &:first-child {
            margin-right: 0.8rem;
        }

        &:last-child {
            margin-left: 0.8rem;
        }

        img {
            width: inherit;
            height: inherit;
        }

        i {
            transition: 0.4s;
        }
    }
    &.center {
        margin: auto;
        margin-top: 2rem;
    }
    .text {
        color: $color-white; 
        z-index: 3;
        @include fz-16;
        position: relative;
        display: block !important;
        white-space: nowrap;
        font-weight: 600;
        line-height: 1;
    }

    &::after {
        content: "";
        position: absolute;
        width: 110%;
        height: 110%;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        transition: 0.3s ease-in-out;
    }

    @include min($res-mobile-min) {
        @include hover {
            &::after {
            opacity: 1;
            
            }
        }
    }



    &-pri {
        @extend .btn;
        background: $color-pri;
        border: 1px solid $color-pri;
        &::after {
            background: rgb(98, 98, 98);
        }

        .text {
            color: $color-white;
        }

        .icon {
            i {
                color: $color-white;
            }
        }

        @include hover {
        border: 1px solid rgb(98, 98, 98);



            // .text {
            //     color: $color-pri;
            // }

            // .icon {
            //     i {
            //         color: $color-white
            //     }
            // }
        }
    }
    &-bd {
        @extend .btn;
        background:transparent;

        &::after {
            background: linear-gradient(90deg, rgba(182, 111, 41, 0.10) 0.37%, rgba(220, 154, 39, 0.10) 27.52%, rgba(237, 179, 31, 0.10) 52.76%, rgba(215, 148, 42, 0.10) 80.98%, rgba(176, 104, 40, 0.10) 100%);
        }

        .text {
            background:$linear;
        }

        .icon {
            i {
                color: $color-white;
            }
        }

        @include hover {
            .text {
                color: $color-pri;
            }

            .icon {
                i {
                    color: $color-pri
                }
            }
        }
    }
    &-second {
        @extend .btn;
        background: $color-white;
        border: 1px solid $color-pri;
        &::after {
            background: rgb(237, 237, 237);
        }

        .text {
            color: $color-pri;
        }

        .icon {
            i {
                color: $color-pri;
            }
        }

        @include hover {

            .text {
                color: rgb(98, 98, 98);
            }

            .icon {
                i {
                    color: rgb(98, 98, 98);
                }
            }
        }
    }
    &.trans {
        background:transparent;
    } 
    &.full {
        width: 100%;
    }
}

.info-see {
    @include mid-flex;
    color: $color-pri;
    width: fit-content;

    i {
        transition: 0.4s;
        margin-top: -0.1rem;
    }

    .text {
        font-weight: 700;
        margin-top: 0rem;
        transition: 0.4s;

        &:last-child {
            margin-left: 0.8rem;
        }

        &:first-child {
            margin-right: 0.8rem;
        }
    }

    @include hover {
        i {
            transform: rotate(360deg);
            color: #DCA200;
        }

        .text {
            color: #DCA200;
        }
    }
}

.bd-ln {
    position: relative;
    width: fit-content;
    display: grid;
    place-items: center;
    padding: 0.2rem;
    &::before {
        content: "";
        position: absolute;
        @include full(0,0,0,0);
        padding: 0.2rem;
        background: $linear;
        border-radius: 100rem;
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }
}