.pdp {
  padding-bottom: 4.5rem;
  &-inner {
    .breadcrumb {
      margin-top: 1.6rem;
    }
  }
  &-flex {
    display: flex;
    margin-top: 1.6rem;

    @include max(800px) {
      flex-direction: column;
      row-gap: 2rem;
    }
  }
  &-left {
    width: calc((7 / 12) * 100%);
    padding-right: 6rem;

    @include max(1100px) {
      padding-right: 2rem;
      width: 50%;
    }
    @include max(800px) {
      width: 100%;
      padding: 0;
    }
  }
  &-right {
    width: calc((5 / 12) * 100%);
    @include max(1100px) {
      width: 50%;
    }
    @include max(800px) {
      width: 100%;
    }
  }
  &-main {
    .swiper {
      @include min(992.9px) {
        &-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 1.6rem;
        }
        &-slide {
          width: calc(50% - 0.8rem);
        }
      }
      &-pagination {
        &-bullet {
          background: #807d7c;
        }
      }
    }
  }
  &-img {
    cursor: pointer;
    .box {
      position: relative;
      padding-top: 100%;
      overflow: hidden;

      img {
        @include imgContain;
      }
    }
  }
  &-name {
    font-size: 7.4rem;
    line-height: 1;

    @include max(768px) {
      font-size: 4.8rem;
    }
  }
  &-star {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    margin-top: 0.5rem;

    .link-re {
      text-decoration: underline;
      color: #807d7c;
      font-weight: 500;
      margin-left: 1rem;
    }

    .star {
      &-flex {
        .icon {
          margin-left: 0.1rem;
          filter: brightness(0) invert(0);
        }
      }
    }
  }
  &-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    flex-wrap: wrap;

    &-left {
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }

    .current,
    .price-new {
      font-weight: 600;
      font-size: 1.8rem;
    }
    .old,
    .price-odd {
      font-weight: 500;
      color: $color-grey;
      text-decoration: line-through;
    }
    .dis {
      color: #dc4136;
      font-weight: 500;
    }

    &-box {
      background: #f9f7f5;
      padding: 0.5rem 1rem;

      .text {
        color: #474140;
      }
    }

    @include max(768px) {
      .current {
        font-size: 1.6rem;
      }
      .old {
        font-size: 1.4rem;
      }
    }
  }
  &-op {
    padding: 2rem 0;
    border-bottom: 0.1rem solid $color-grey-3;

    @include max(800px) {
      &.ctr {
        border-bottom: 0;
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &-item {
      padding-bottom: 1.5rem;

      &:last-child {
        padding-bottom: 0;
      }
    }
    .kt {
      .recheck {
        &-text {
          margin: 0;
          padding: 0.5rem 1rem;
          border: 0.1rem solid rgba(26, 26, 26, 0.4);
          color: rgba(26, 26, 26, 0.4);
          font-weight: 600;
        }
        &-item {
          &.active {
            .recheck-text {
              border-color: #1a1a1a;
              color: #1a1a1a;
            }
          }
        }
      }
    }
    .sz {
      .recheck {
        &-checkbox {
          width: 2.8rem;
          height: 2.8rem;
          border-radius: 100rem;
          padding: 0.3rem;
          border: none;
          background: none !important;

          &::before {
            display: none;
          }
        }
        &-item {
          &.active {
            .recheck-checkbox {
              border: 0.1rem solid black;
            }
          }
        }
      }
    }
    &-flex {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      @include max(768px) {
        flex-direction: column;
      }
    }
    &-left {
      width: 28%;
      @include max(768px) {
        width: 100%;
      }
    }
    &-right {
      flex: 1;
      display: flex;

      .recheck {
        flex: 1;
      }
      .sizeguide {
        width: max-content;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &.ctr {
    }

    .recheck {
      &-block {
        display: flex;
        gap: 0.8rem;
        flex-wrap: wrap;
      }
    }

    .select2-container {
      width: 100% !important;
    }

    .select2-selection {
      height: 3.6rem;
      border-radius: 0;
      border: 0.1rem solid black;
    }

    .select2-selection__rendered {
      height: 3.6rem;
      display: flex !important;
      align-items: center;
      font-weight: 600;
    }
    .select2-selection__arrow {
      height: 3.6rem !important;
    }
  }
  &-ch {
    position: relative;
    &-flex {
      display: flex;
      gap: 2rem;
    }
    &-left {
      flex: 1;
    }
    &-right {
      width: max-content;
      gap: 1rem;
    }
    &-top {
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 0.8rem;

      .icon {
        display: flex;
        i {
          transition: 0.3s ease-in-out;
        }
      }
    }
    &-ctn {
      position: absolute;
      right: 0;
      top: 100%;
      width: 33rem;
      padding: 2rem;
      border: 0.1rem solid $color-pri;
      background: white;
      z-index: 15;
      opacity: 0;
      transform: translateY(1rem);
      transition: 0.3s ease-in-out;
      visibility: hidden;
    }
    &-list {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }
    &-item {
      display: flex;
      gap: 0.8rem;

      .icon {
        width: 1.6rem;
        padding-top: 0.2rem;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .text {
        flex: 1;
      }
      .link {
        display: block;
        color: #1454d3;
        text-decoration: underline;
        font-weight: 500;
      }
    }
    &.show {
      .pdp-ch-top {
        .icon {
          i {
            transform: rotate(180deg);
          }
        }
      }
      .pdp-ch-ctn {
        opacity: 1;
        transform: translate(0);
        visibility: visible;
      }
    }
  }
  &-gq {
    width: 100%;
    &-text {
      .ip-control {
        margin-top: 0.8rem;
      }
      display: none;
    }
    textarea {
      min-height: 8rem;
      border: 1px solid #1a1a1a;
      background: #fff;
      width: 100%;
      padding: 0.8rem 1.6rem;

      &:focus {
        outline: none;
        border-color: black;
      }
    }
    &.active {
      .pdp-gq-text {
        display: block;
      }
    }
  }
  &-dk {
    @include max(800px) {
      display: none;
    }
    &-flex {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-left {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
    &-tag {
      background-color: #e52346;
      @include mid-flex;
      padding: 0.2rem 0.8rem;
      .text {
        color: white;
        font-weight: 600;
      }
    }
    &-slide {
      padding-top: 2rem;

      .swiper {
        &-slide {
          height: auto !important;
        }
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.8rem;
      height: 100%;
    }
    &-img {
      position: relative;
      width: 100%;
      .box {
        padding-top: 100%;
        position: relative;
        overflow: hidden;

        img {
          @include imgContain;
        }
      }

      @include hover {
        .pdp-dk-add {
          display: flex;
        }
      }
    }
    &-add {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #ecebea;
      @include mid-flex;
      padding: 0.5rem 0;
      cursor: pointer;
      display: none;
      @include max(1024px) {
        display: flex;
        position: relative;
      }

      .icon {
        width: 2.4rem;
        height: 2.4rem;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    &-desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      flex: 1;
    }
    &-name {
      font-weight: 600;
      font-size: 1.2rem;
      transition: 0.3s ease-in-out;

      @include hover {
        color: rgb(98, 98, 98);
      }
    }
    &-price {
      margin-top: auto;
      .current,
      .price-new {
        font-weight: 600;
        font-size: 1.2rem;
      }
      .old,
      .price-odd {
        font-weight: 500;
        font-size: 1.2rem;
        text-decoration: line-through;
        color: #807d7c;
      }
    }
    &-btn {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      gap: 0.8rem;

      &-next,
      &-prev {
        position: relative;
        inset: 0;
        margin: 0 !important;
        width: 2.8rem;
        height: 2.8rem;
        border: 0.1rem solid black;
        border-radius: 100rem;

        &:after {
          display: none;
        }

        i {
          color: black;
        }
      }
    }
  }
  &-control {
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;

    .btn {
      flex: 1;
    }
    &-wish {
      height: 4.4rem;
      width: 4.4rem;
      @include mid-flex;
      padding: 0.8rem;
      cursor: pointer;

      .icon {
        display: flex;

        i {
          font-size: 2.4rem;
        }
      }

      &.active {
        .icon {
          i {
            color: #eb2f3a;
            font-weight: 700;
          }
          img {
            filter: brightness(0) saturate(100%) invert(26%) sepia(27%)
              saturate(6234%) hue-rotate(330deg) brightness(90%) contrast(106%);
          }
        }
      }
    }

    @include max(600px) {
      flex-wrap: wrap;
      padding-top: 0;
      .btn-pri {
        width: 100%;
        flex: unset;
      }
    }
  }
  &-poli {
    margin-top: 1.2rem;
    &-item {
      display: flex;
      margin-bottom: 0.5rem;
      gap: 0.8rem;

      .icon {
        display: flex;
      }
    }
  }
  &-info {
    border-bottom: 0.1rem solid $color-grey-3;
    padding-bottom: 1rem;
    &-top {
      padding: 1rem 0;
      border-bottom: 0.1rem solid $color-grey-3;
      .text {
        line-height: 1;
      }
    }
    &-desc {
      padding: 1.6rem 0;

      > .text {
        margin-bottom: 1.6rem;
      }
      table {
        margin-bottom: 0 !important;
      }
    }
    &-flex {
      display: flex;
      grid-area: 0.8rem;
    }
    &-left {
      width: 28%;
    }
    &-right {
      flex: 1;
    }
  }
  &-more {
    &-top {
      border-bottom: 0.1rem solid $color-grey-3;
      display: flex;
      justify-content: space-between;

      @include max(800px) {
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    &-item {
      line-height: 1;
      width: calc(100% / 3);
      text-align: center;
      transition: 0.3s ease-in-out;
      padding: 1.2rem 0;
      font-weight: 500;
      color: #807d7c;
      cursor: pointer;

      @include max(800px) {
        min-width: max-content;
        padding: 1.2rem;
      }

      &.active {
        font-weight: 600;
        border-bottom: 0.1rem solid black;
        color: $color-pri;
      }
    }
    &-ctn {
      font-weight: 500;
      padding: 1.6rem 0;
      ul {
        padding-left: 2rem;
        list-style-type: disc;
        li {
        }
      }
    }
    &-tab {
      &:not(:first-child) {
        display: none;
      }

      ul {
        margin-bottom: 0 !important;
      }
    }
  }
  &-fixed {
    position: fixed;
    bottom: 2rem;
    width: calc(100% - 3rem);
    left: 50%;
    max-width: 166rem;
    z-index: 99999;
    border-radius: 0.4rem;
    transition: 0.3s ease-in-out;
    opacity: 0;
    transform: translateX(-50%) translateY(200%);
    visibility: hidden;
    @include min(768px) {
        display: none;
    }

    &-inner {
      background: white;
      padding: 1rem;
      border-radius: 0.4rem;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      display: flex;
      gap: 1rem;

      .btn {
        flex: 1;
      }
    }
    &.sticky {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
}

.sre {
  padding: 8rem 0;
  border-bottom: 1px solid #1a1a1a;
  @include max(768px) {
    padding: 6rem 0;
  }
  &-top {
    .title {
      @include fz-45;

      @include max(768px) {
        font-size: 3.6rem;
      }
    }
    &-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-left {
      display: flex;
      align-items: center;
      gap: 2.4rem;
      flex-wrap: wrap;
    }
  }
  &-btn {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    gap: 0.8rem;
    @include max(768px) {
      display: none;
    }

    &-next,
    &-prev {
      position: relative;
      inset: 0;
      margin: 0 !important;
      width: 4rem;
      height: 4rem;
      border: 0.1rem solid black;
      border-radius: 100rem;

      &:after {
        display: none;
      }

      i {
        color: black;
      }
    }
  }
  &-title {
    font-weight: 600;
    @include fz-16;
    margin-top: 1.6rem;
    line-height: 1;
  }
  &-item {
    height: 100%;
  }
  &-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #f9f7f5;
    padding: 3.2rem;

    @include max(768px) {
      padding: 3.2rem 1.6rem;
    }
  }
  &-desc {
    margin-bottom: 1.6rem;
  }
  &-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }
  &-name {
    font-weight: 500;
    font-size: 1.2rem;
  }
  &-date {
    color: $color-grey;
    font-weight: 500;
    font-size: 1.2rem;
  }
  &-slide {
    margin-top: 2.4rem;
    .swiper {
      &-slide {
        height: auto !important;
      }
    }
  }
}

.spro {
  padding: 8rem 0;
  border-bottom: 1px solid #1a1a1a;
  overflow: hidden;

  @include max(768px) {
    padding: 6rem 0;
  }

  &-top {
    margin-bottom: 2.4rem;

    &-flex {
      display: flex;
      gap: 0.8rem;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @include max(768px) {
        align-items: flex-start;
        .spro-top-right {
          padding-top: 1.7rem;

          .link {
            font-size: 1.4rem;
          }
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
      gap: 2.4rem;
      width: calc(100% - 21rem);
      @include max(768px) {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      gap: 2.4rem;

      .link {
        text-decoration: underline;
        transition: 0.3s ease-in-out;
        width: max-content;
        @include hover {
          color: $txt-hover;
        }
      }
    }

    .recheck {
      &-block {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      &-text {
        margin: 0;
        width: max-content;
      }
    }
  }

  &-fill {
    overflow-x: auto;

    @include max(768px) {
      width: calc(100vw - 3rem);
    }
  }

  &-title {
    @include fz-45;
    white-space: nowrap;

    @include max(768px) {
      font-size: 3.6rem;
    }
  }

  &-slide {
    padding-left: 4rem;
    // &-inner {
    //     padding-left: calc((100vw - 192rem) / 2);
    // }
    @include max(1200px) {
      padding-left: 1.5rem;
    }
  }

  &-btn {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    gap: 0.8rem;
    @include max(800px) {
      display: none;
    }

    &-next,
    &-prev {
      position: relative;
      inset: 0;
      margin: 0 !important;
      width: 4rem;
      height: 4rem;
      border: 0.1rem solid black;
      border-radius: 100rem;

      &:after {
        display: none;
      }

      i {
        color: black;
        font-size: 1.6rem;
      }
    }
  }
}

.select2-results__option {
  font-weight: 600;
}
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background: #ecebea;
  color: black;
}
.select2-container--default .select2-results__option--selected {
  font-weight: 600;
}
