.acc {
  padding: 6rem 0;
  border-bottom: 0.1rem solid $color-pri;
  @include max(768px) {
    padding: 4rem 0;
  }
  &-inner {
    width: calc((8/12) * 100%);
    margin: 0 auto;

    @include max(1200px) {
      width: 100%;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    flex-wrap: wrap;
    margin-bottom: 1.2rem;
    .title {
      font-size: 5.2rem;
      line-height: 1;
    }
  }
  &-rank {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 3.8rem;
    padding: 0.4rem 1.2rem;
    border: 0.1rem solid $color-pri;
    border-radius: 10rem;

    .text {
      line-height: 1;
    }

    .icon {
      display: flex;
      height: 100%;
      img {
        object-fit: contain;
        height: 100%;
      }
    }
  }
  &-add {
    .text {
    }
  }
  &-pro {
    &-desc {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      width: max-content;
      .text {
        font-weight: 700;
        line-height: 1;
      }
      .icon {
        display: flex;
        width: 2.4rem;
        height: 2.4rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      @include max(650px) {
        .text {
          display: none;
        }
        background: none !important;
        padding: 0 !important;
      }
    }
    &-line {
      position: absolute;
      left: 0;
      top: 0;
      height: 0.2rem;
      background: $color-pri;
      width: 50%;
      z-index: 3;

      .icon {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        width: 1.8rem;
        height: 1.8rem;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    &-list {
      display: flex;
      margin-top: 1.5rem;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 0.2rem;
        background: #eed4bd;
      }
    }
    &-item {
      width: calc(100% / 3);
      padding-top: 1rem;
      position: relative;
      display: flex;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0.1rem;
        transform: translateY(-50%);
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 100rem;
        background: #eed4bd;
        z-index: 2;
      }

      &.x1 {
        &::after {
          background: $color-pri;
        }
      }
      &.x2 {
        justify-content: space-between;

        &:before {
          position: absolute;
          content: "";
          right: 0;
          top: 0.1rem;
          transform: translateY(-50%);
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 100rem;
          background: #eed4bd;
          z-index: 2;
        }
      }
      &:not(.x1) {
        &::after,
        &::before {
          border: 0.2rem solid white;
        }
      }

      &:last-child {
        justify-content: flex-end;
        &::after {
          left: unset;
          right: 0;
        }
        .acc-pro-desc {
          padding: 0.4rem 1.2rem;
          border-radius: 2.4rem;
          background: linear-gradient(
            180deg,
            #cad8f3 0%,
            #88a1d1 41.15%,
            #364767 98.87%
          );

          .text {
            color: white;
          }
        }
      }

      @include max(650px) {
        &.x1 {
          .acc-pro-desc {
            margin-left: -0.6rem;
          }
        }
        &.x2 {
          .acc-pro-desc {
            &:first-child {
              margin-left: -0.6rem;
            }
            &:last-child {
              margin-right: -0.6rem;
            }
          }
        }
        &:last-child {
          .acc-pro-desc {
            margin-right: -0.6rem;
          }
        }
      }

      &.active {
        &::after {
          background: $color-pri;
        }

        &-2 {
          &::before {
            background: $color-pri;
          }
        }
      }
    }
  }
  .text-str {
    background-clip: text !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    &.si {
      background: -webkit-linear-gradient(
        90deg,
        #111 0%,
        #a7a5a5 54.63%,
        #181819 100%
      );
    }
    &.go {
      background: linear-gradient(
        180deg,
        #bf7c17 0%,
        #ffb801 41.67%,
        #aa6d25 100%
      );
    }
  }

  &-list {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1rem solid $color-grey-3;
    margin: 4rem 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-item {
    padding: 0.8rem 1.6rem;
    font-weight: 500;
    opacity: 0.5;
    position: relative;
    transition: 0.3s ease-in-out;
    display: flex;
    @include fz-12;
    flex: 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: max-content;
    white-space: nowrap;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.1rem;
      width: 0;
      background: $color-pri;
      transition: 0.3s ease-in-out;
    }
    @include hover {
      opacity: 1;
      &::after {
        width: 100%;
      }
    }
    &.active {
      opacity: 1;
      font-weight: 600;
      &::after {
        width: 100%;
      }
    }
  }

  &-mpro {
    &-flex {
      display: flex;
      margin: 0 -1.2rem;
      margin-bottom: 1.6rem;
      @include max(600px) {
        flex-direction: column;
        gap: 0.8rem;
      }
    }
    &-left {
      padding: 0 1.2rem;
      width: 25%;
      @include max(600px) {
        width: 100%;
      }

      .text {
        font-weight: 500;
        display: flex;
        flex-direction: column;
      }
    }
    &-right {
      flex: 1;
      padding: 0 1.2rem;
      display: flex;
      align-items: center;
    }
  }

  .ip-control {
    height: 4.8rem;
    position: relative;

    .text-abs {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.4s;
      pointer-events: none;
      z-index: 2;
      left: 1.2rem;
      line-height: 1;
    }
    input {
      outline: none;
      width: 100%;
      border: 1px solid #f3edea;
      transition: 0.3s;
      height: 4.8rem;
      padding: 0 1.2rem;
      font-weight: 500;
      background: #f9f7f5;
    }
    &.active {
      .text-abs {
        transform: translateY(0);
        top: 0.5rem;
        font-size: 1rem;
      }
      input {
        padding-top: 1.5rem;
      }
    }

    .select2-container {
      width: 100% !important;
      height: 100%;
      font-size: 1.2rem;
    }
    .select2-search--dropdown {
      display: none;
    }
    .select2-container .select2-selection--single,
    .select2-selection__rendered {
      height: 100%;
      display: flex;
      align-items: center;
      background: #f9f7f5;
      border-radius: 0;
      width: 100%;
      border-color: transparent;
    }
    .select2-selection__rendered {
      border: 1px solid #f3edea;
    }
    .select2-selection__arrow {
      height: 100% !important;
    }
    &.gc {
      input {
        padding-left: 4rem;
      }
    }
    .icon-abs {
      position: absolute;
      left: 1.6rem;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .change-pass {
      cursor: pointer;
      font-weight: 500;
    }

    .text-show {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.2rem;
      cursor: pointer;

      .text {
        text-decoration: underline;
        font-weight: 500;

        &:nth-child(2) {
          display: none;
        }
      }

      &.show {
        .text {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
        }
      }
    }
  }
  .recheck {
    &-block {
      display: flex;
      gap: 2.4rem;
    }
    &-checkbox {
      height: 1.6rem;
      width: 1.6rem;
      border: 1px solid $color-grey-3;

      &::before {
        font-weight: 700;
      }
    }
    &-item {
      &.active {
        .recheck-checkbox {
          background: $color-second;
          border-color: $color-second;
        }
      }
    }
  }

  &-card {
    table {
      width: 100%;
      margin-top: 2rem;
      thead {
        background-color: #ecebea;
        th {
          border: 0.1rem solid $color-pri;
          padding: 1rem 1.6rem;
          vertical-align: middle;
        }
      }
      tbody {
        tr {
          border: 0.1rem solid $color-pri;

          td {
            padding: 1rem 1.6rem;
            border-right: 0.1rem solid $color-pri;
            vertical-align: middle;
            text-align: center;
            height: 6.4rem;

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }

    &-table {
      &.pc {
        @include max(768px) {
          display: none;
        }
      }
      &.mb {
        @include max(768px) {
          display: block;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }
    &-item {
      width: 100%;
      border: 0.1rem solid $color-pri;

      &:nth-child(1) {
        .acc-card-title {
          background-color: #faf6f4;
        }
      }
      &:nth-child(2) {
        .acc-card-title {
          background: linear-gradient(
              91deg,
              #cfcfcf -1.35%,
              #eee 45.49%,
              #d3d3d3 95.34%
            ),
            #ecebea;
        }
      }
      &:nth-child(3) {
        .acc-card-title {
          background: linear-gradient(
              136deg,
              #f1a849 -0.1%,
              #fbdda3 46.74%,
              #ef9a35 103.19%
            ),
            #ecebea;
        }
      }
      &:nth-child(4) {
        .acc-card-title {
          background: linear-gradient(
              271deg,
              #8ba0bf -1.32%,
              #e5eaf1 47.63%,
              #8ba0bf 110.08%
            ),
            #ecebea;
        }
      }
    }
    &-title {
      height: 4.8rem;
      @include mid-flex;
      border-bottom: 0.1rem solid $color-pri;
      .acc-pro-desc .text {
        display: block !important;
      }
    }
    &-desc {
      padding: 0.8rem 1.6rem;

      .text {
        margin-bottom: 0.5rem;
      }

      ul {
        padding-left: 2rem;
        list-style: disc;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        li {
          font-weight: 500;
        }
      }
    }
  }
  &-add {
    &-flex {
      display: flex;
      margin: 0 -1.2rem;
      margin-bottom: 1.6rem;
      @include max(600px) {
        flex-direction: column;
        gap: 0.8rem;
      }
    }
    &-left {
      padding: 0 1.2rem;
      width: 25%;
      @include max(600px) {
        width: 100%;
      }

      .text {
        font-weight: 500;
        display: flex;
        flex-direction: column;
      }
    }
    &-right {
      flex: 1;
      padding: 0 1.2rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 1.6rem;
      margin: 0 -0.4rem;

      .ip-control {
        padding: 0 0.4rem;
      }
    }
    &-btn {
      padding: 0 0.4rem;
    }
    .btn-ctr-js {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-in-out;
      margin-bottom: -4.4rem;
    }

    &.show {
      .btn-ctr-js {
        margin-bottom: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &-vou {
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1.2rem;

      .pcart-tag-item {
        width: calc(100% / 3 - 0.8rem);
        height: auto;
      }

      @include max(900px) {
        .pcart-tag-item {
          width: calc(50% - 0.6rem);
        }
      }
      @include max(600px) {
        .pcart-tag-item {
          width: 100%;
        }
      }
    }
  }
  &-bill {
    &-list {
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }
    &-item {
        border: 0.1rem solid $color-pri;
    }
    &-top {
        height:6rem;
        display: flex;
        align-items: center;
        justify-content:space-between;
        padding:0 2.4rem;
        background: #ECEBEA;
        border-bottom: 0.1rem solid $color-pri;


        .text {
            font-size:2.8rem;
        }
    }
    &-desc {
        padding: 2.4rem;
    }
    &-box {
        &:not(:first-child) {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 0.1rem solid $color-grey-3;
        }

        .pcart-item {
            &:not(:first-child) {
                margin-top: 0.8rem;
            }
        }
    }
  }
  &-info {
    display: flex;
    gap: 1rem;justify-content: space-between;
    margin-bottom: 1.6rem;
    flex-wrap: wrap;
    @include max(600px) {
        flex-direction: column;
        gap: 0.8rem;
    }

    .text {
        display: flex;
        gap: 0.5rem;
        color: #474140;
        flex-wrap: wrap;
        @include max(400px) {
            .status {
                width: 100%;
            }
        }


        .code {
            text-decoration: underline;
        }
    }
  }
  .status {
    font-style: italic;
    font-weight: 500;

    &.cbh {
        color: #1454D3;
    }
    &.dg {
        color: rgba(11, 174, 98, 1);
    }
    &.dh {
        color: rgba(220, 65, 54, 1);
    }
  }
}
