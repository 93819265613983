.hban {
  &-wrap {
  }
}
.hban1 {
  overflow: hidden;
  &-flex {
    display: flex;
    min-height: calc((640 / 1440) * 100vw);
    @include max(650px) {
      flex-direction: column;
    }
  }
  &-left {
    width: 33.3%;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    @include max(1200px) {
      padding: 3rem 1.5rem;
    }
    @include max(650px) {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
    }
    @include max(450px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-right {
    flex: 1;
  }
  &-top {
    .text {
      color: #807d7c;
    }
    .title {
      line-height: 1;
      font-size: 10rem;
    }
    @include max(992px) {
      .title {
        font-size: 6rem;
      }
    }
  }
  &-bot {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @include max(650px) {
      margin-top: unset;
      align-items: flex-end;

      .text {
        text-align: right;
      }
    }
    @include max(450px) {
      .text {
        text-align: left;
      }
      align-items: flex-start;
    }
  }
  &-img {
    width: 100%;
    display: flex;
    height: 100%;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
}
.hban4 {
  overflow: hidden;
  &-flex {
    display: flex;
    min-height: calc((640 / 1440) * 100vw);
    @include max(650px) {
      flex-direction: column;
    }
  }
  &-left {
    width: 33.3%;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    @include max(1200px) {
      padding: 3rem 1.5rem;
    }
    @include max(650px) {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
    }
    @include max(450px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-right {
    flex: 1;
  }
  &-top {
    .text {
      color: #807d7c;
    }
    .title {
      line-height: 1;
      font-size: 10rem;
    }
    @include max(992px) {
      .title {
        font-size: 6rem;
      }
    }
  }
  &-bot {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @include max(650px) {
      margin-top: unset;
      align-items: flex-end;

      .text {
        text-align: right;
      }
    }
    @include max(450px) {
      .text {
        text-align: left;
      }
      align-items: flex-start;
    }
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  &-img {
    width: 100%;
    display: flex;
    height: 100%;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    &:nth-child(2) {
      grid-area: 1 / 2 / 3 / 3;
    }
    &:nth-child(3) {
      grid-area: 2 / 1 / 3 / 2;
    }
  }
}
.hban2 {
  &-item {
    height: calc((640 / 1440) * 100vw);
    position: relative;
    @include max(768px) {
      height: calc(100vh - var(--size-hd));
    }
  }
  &-ctn {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    padding: 6rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 2;

    .text {
      color: white;
      font-size: 1.6rem;
      font-weight: 600;
    }
    .title {
      color: white;
      font-weight: 700;
      line-height: 1;
      font-size: 7.4rem;
      text-align: center;
    }
    .link {
      color: white;
      text-decoration: underline;
      font-weight: 500;
    }
    @include max(768px) {
      padding: 4rem 1.5rem;
    }
  }
  &-img {
    display: flex;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.hban3 {
  &-slide {
    position: relative;
  }
  &-thumb {
    position: absolute;
    width: 10rem;
    right: 4rem;
    bottom: 4rem;
    max-height: calc(100% - 8rem);
    overflow-y: auto;
    z-index: 3;

    &::-webkit-scrollbar {
      display: none;
    }
    @include max(768px) {
      right: 1.5rem;
      width: 8rem;
    }

    .swiper {
      &-wrapper {
        justify-content: flex-end;
      }
      &-slide {
        height: max-content;
      }
      &-slide-thumb {
        &-active {
          .hban3-img {
            border: 0.1rem solid #807d7c;
          }
        }
      }
    }
  }
  &-img {
    position: relative;
    padding-top: calc((75 / 100) * 100%);
    overflow: hidden;
    border: 0.1rem solid transparent;
    transition: 0.3s ease-in-out;

    img {
      @include imgCover;
    }
  }
  &-item {
    position: relative;
    min-height: calc((640 / 1440) * 100vw);
  }
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-ctn {
    position: relative;
    z-index: 2;
    padding: 8rem 4rem;
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    transition: 1.3s ease-in-out;

    @include max(768px) {
      padding: 4rem 1.5rem;
      padding-right: 10rem;
    }

    .cate {
      font-weight: 600;
      color: #e5ded8;
    }
    .title {
      color: white;
      font-weight: 700;
      font-size: 10rem;
      line-height: 1;
      @include max(768px) {
        font-size: 6rem;
      }
    }
    .text {
      color: white;
      font-weight: 500;
    }
    .link {
      text-decoration: underline;
      font-weight: 500;
      margin-top: 2.4rem;
      display: block;
      color: white;
    }
  }
  .hban3Swiper {
    .swiper-slide {
      &-active {
        .hban3-ctn {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }
}
.hban5 {
  &-item {
    height: calc((640 / 1440) * 100vw);
    position: relative;
    @include max(768px) {
      height: calc(100vh - var(--size-hd));
    }
  }
  &-ctn {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    padding: 6rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;

    > .text {
      color: white;
      font-size: 1.6rem;
      font-weight: 600;
    }
    .title {
      color: white;
      font-weight: 700;
      line-height: 1;
      font-size: 10rem;
      text-align: center;
      @include max(768px) {
        font-size: 6rem;
      }
    }
    .btn {
      border-color: white !important;
      margin-top: 1.6rem;

      .text {
        line-height: 1;
      }
    }

    @include max(768px) {
      padding: 4rem 1.5rem;
    }
  }
  &-img {
    display: flex;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-desc {
    position: absolute;
    left: 4rem;
    bottom: 4rem;
    z-index: 1;
    color: white;
    font-weight: 500;
    opacity: 0;
    transform: scale(0.7);
    transition: 0.4s ease-in-out;
    padding-right: 25%;
    @include max(768px) {
      padding-right: 40%;

      font-size: 1.2rem;
      left: 1.5rem;
      br {
        display: none;
      }
    }
  }
  &-pagi {
    position: absolute;
    right: 4rem;
    bottom: 4rem;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include max(768px) {
      right: 1.5rem;
    }

    .num {
      font-weight: 600;
      color: white;
      font-size: 3.6rem;
    }

    .swiper {
      &-pagination {
        position: relative;
        margin: 0;
        inset: 0 !important;
        width: 4rem;
        height: 0.2rem;
        opacity: 0.5;

        &-progressbar {
          &-fill {
            background: white;
          }
        }
      }
    }
  }
  .swiper {
    &-slide {
      &-active {
        .hban5-desc {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
.hban6 {
  &-inner {
    min-height: calc((640 / 1440) * 100vw);
  }
  &-flex {
    display: flex;
    padding: 2.4rem 0;
    margin: 0 -0.8rem;
    @include max(650px) {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  &-left {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    padding: 0 0.8rem;
    @include max(650px) {
      flex-direction: row;
      width: 100%;
      gap: 1.5rem;
    }

    &-img {
      height: 100%;
      width: 100%;
      @include max(650px) {
        padding-top: calc((410 / 400) * 100%);
        position: relative;
        overflow: hidden;

        img {
          @include imgCover;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-right {
    flex: 1;
    padding: 0 0.8rem;
  }
  &-img {
    height: 100%;
    width: 100%;

    @include max(650px) {
      padding-top: calc((488 / 944) * 100%);
      position: relative;
      overflow: hidden;

      img {
        @include imgCover;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-top {
    margin-bottom: 1.6rem;
    &-flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
    }
    &-left {
      @include max(768px) {
        .text {
          br {
            display: none;
          }
        }
      }
    }
    &-right {
      width: max-content;
    }
    .text {
      color: #807d7c;
      font-weight: 500;
    }
    .link {
      font-weight: 500;
      text-decoration: underline;
    }
  }
  &-bot {
    flex: 1;
    @include max(650px) {
      width: 30rem;
      flex-shrink: 0;
      flex: unset;
    }
  }
  &-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;
    .title {
      line-height: 1;
      font-size: 10rem;

      @include max(768px) {
        font-size: 6rem;
      }
    }
    .cate {
      color: #807d7c;
      font-weight: 600;
    }
  }
}

.hcollec {
  overflow: hidden;
  &-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: calc((100% / 6));
    position: relative;
    border-right: 0.1rem solid $color-pri;
    padding: 2.4rem;
    border-top: 0.1rem solid $color-pri;

    @include max(992px) {
      padding: 1.6rem;
    }
    @include max(900px) {
      width: calc(100% / 3);
      border-bottom: 0.1rem solid $color-pri;
      &:nth-child(3) {
        border-right: none;
      }
      &:nth-child(4) {
        border-top: none;
      }
      &:nth-child(5) {
        border-top: none;
      }
      &:nth-child(6) {
        border-top: none;
      }
    }
    @include max(500px) {
      width: 50%;
      &:nth-child(3) {
        border-right: 0.1rem solid $color-pri;
        border-top: none;
      }
      &:nth-child(2) {
        border-right: none;
      }
      &:nth-child(4) {
        border-right: none;
      }
    }

    &:nth-child(odd) {
      background-color: white;
    }
    &:nth-child(even) {
      background: #f9f7f5;
    }
    &:last-child {
      border-right: none;
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05) !important;
      }
    }
  }
  &-img {
    margin-top: 4rem;
    @include max(992px) {
      margin-top: 0;
    }
    .box {
      padding-top: 100%;
      position: relative;
      overflow: hidden;
      display: block;

      img {
        @include imgContain;
        transition: 0.3s ease-in-out;
      }
    }
  }
  &-link {
    cursor: pointer;
    margin-top: 4rem;
    font-weight: 500;

    @include max(992px) {
      margin-top: 1.6rem;
    }
    @include max(500px) {
      margin-top: 0.8rem;
    }
  }
  &-flex {
    display: flex;
    flex-wrap: wrap;
  }
  &-bot {
    &-item {
      width: 50%;
      position: relative;
      @include max(700px) {
        width: 100%;
        margin-left: 0 !important;
      }
      &:nth-child(2) {
        margin-left: -0.1rem;
      }
    }
    &-img {
      display: flex;
      padding-top: calc((540 / 720) * 100%);
      position: relative;
      overflow: hidden;

      img {
        @include imgCover;
        transition: 0.4s ease-in-out;
      }
      @include hover {
        img {
          transform: translate(-50%, -50%) scale(1.05);
        }
      }
    }
  }
  &-desc {
    position: absolute;
    bottom: 6rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    @include max(768px) {
      bottom: 1.6rem;

      .title {
        font-size: 3.6rem !important;
      }
    }

    .title {
      font-size: 4.4rem;
    }
    .link {
      font-size: 1.6rem;
      text-decoration: underline;
      font-weight: 600;
      color: white;
    }
  }
}

.hslide {
  overflow: hidden;
  &-ctn {
    padding: 7rem 0;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    @include max(1100px) {
      padding-bottom: 13rem;
    }
    @include max(768px) {
      padding: 6rem 0;
    }

    &-top {
      padding: 0 1.5rem;
      .title {
        font-size: 4.4rem;
        line-height: 1.2;
        @include max(768px){
          font-size:3.6rem;
        }
      }
      .text {
        color: #807d7c;
      }
    }
  }
  &-flex {
    display: flex;
    @include max(992px) {
      flex-direction: column;
    }
  }
  &-left {
    width: calc((4 / 12) * 100%);
    @include max(992px) {
      width: 100%;
    }
  }
  &-right {
    width: calc((8 / 12) * 100%);
    background-color: #f9f7f5;
    @include max(992px) {
      width: 100%;
    }
  }
  &-ban {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include max(992px) {
      padding-top: calc((200/375) * 100%);position: relative;
      overflow: hidden;

      img {
        @include imgCover;
      }
    }
  }
  &-img {
    .box {
      position: relative;
      overflow: hidden;
      padding-top: calc((500 / 375) * 100%);
      display: block;

      img {
        @include imgContain;
        transition: 0.4s linear;
      }
    }
    @include hover {
      img {
        transform: translate(-50%,-50%)scale(1.05);
      }
    }
  }
  &-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-name {
    font-weight: 600;
  }
  &-text {
    font-weight: 500;
    color: #807d7c;
  }
  &-price {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.8rem;
    .price-new {
      font-weight: 600;
    }
    .price-old {
      font-size: 1.2rem;
      font-weight: 500;
      text-decoration: line-through;
      color: #807d7c;
    }
  }
  &-btn {
    // position: relative;
    // display: flex;
    // flex-direction: row-reverse;
    gap: 0.8rem;


    &-next,
    &-prev {
      // position: relative;
      // inset: 0;
      // margin: 0 !important;
      width: 4rem;
      height: 4rem;
      border: 0.1rem solid black;
      border-radius: 100rem;

      &:after {
        display: none;
      }

      i {
        color: black;
        font-size: 1.6rem;
      }
    }
  }
  &-slide {
    padding: 0 15%;
    @include max(550px) {
      padding:0 0.5rem;
    }
    .hslideSwiper {
      z-index: 2;
    }
  }
  &-item {
    padding: 0 22%;
    @include max(550px) {
      padding: 0 6rem;
    }
  }
  &-thumb {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 2.4rem;
    z-index: 1;
    @include max(768px) {
      position:relative;
      padding: 0;
      padding-top: 1.6rem;
    }

    .hslide-color,
    .hslide-review {
      transition: 0.3s ease-in-out;
      opacity: 0;
      transform: scale(0);
      visibility: hidden;
    }
    .hslide-review {
    }
    .swiper {
      &-slide {
        &-thumb {
          &-active {
            .hslide-color,
            .hslide-review {
              transform: scale(1);
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  &-bot {
    &-flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include max(768px) {
        justify-content:center;
      }
    }
    &-right {
      @include max(768px) {
        display: none;
      }
    }
  }
  &-color {
    border-radius: 1.2rem;
    background: #fff;
    padding: 1.2rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;

    &-item {
      display: flex;
      border-radius: 100rem;
      width: 3.2rem;
      height: 3.2rem;
    }
  }
  &-review {
    width: 19rem;
    padding: 1.2rem;
    background: white;
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &-img {
      .box {
        padding-top: calc((100 / 164) * 100%);
        position: relative;
        overflow: hidden;
        display: block;
        border-radius: 1rem;

        img {
          @include imgCover;
        }
      }
    }

    &-desc {
      .text {
        font-size: 1.2rem;
        color: #807d7c;
      }
    }
    &-bot {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: space-between;
      margin-top: 1rem;

      .name {
        font-size: 1.2rem;
        color: #474140;
        font-weight: 600;
      }
      .star-flex .icon:not(:first-child) {
        margin-left: 0;
      }
      .star-flex .icon {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
}

.hnew {
  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    @include max(768px) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.4rem;
      padding: 6rem 0.8rem;
      row-gap: 0.8rem;
    }
  }
  &-col {
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    &:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    &:nth-child(3) {
      grid-area: 1 / 3 / 2 / 4;
    }
    &:nth-child(4) {
      grid-area: 2 / 1 / 3 / 2;
    }
    &:nth-child(5) {
      grid-area: 2 / 2 / 3 / 3;
    }
    &:nth-child(6) {
      grid-area: 2 / 3 / 3 / 4;
    }
    @include max(768px) {
      padding: 0 0.4rem;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(3) {
        width: 50%;
      }
      &:nth-child(4) {
        width: 50%;
      }
      &:nth-child(5) {
        width: 50%;
      }
      &:nth-child(6) {
        display: none;
      }
    }
  }
  &-img {
    aspect-ratio: 1;
    display: flex;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.4s linear;
    }
    @include hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  &-title {
    padding: 4rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;

    @include max(992px) {
      padding: 2rem 1.5rem;

      .title {
        font-size: 3.6rem !important;
      }
    }

    @include max(768px) {
      padding-top: 0;
    }

    .title {
      font-size: 4.4rem;
      line-height: 1.2;
    }
    .text {
      color: #807d7c;
    }
  }
  &-link {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 4rem;
    .link {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

.hslo {
  border-top: 0.1rem solid $color-pri;
  &-inner {
    padding: 12rem 0;
    margin: 0 auto;
    width: 58%;

    @include max(1200px) {
      width: 80%;
    }
    @include max(768px) {
      width: 100%;
      padding: 12rem 1.5rem;

      .text {
        font-size: 1.8rem !important;
      }
    }
    .text {
      font-style: italic;
      font-size: 2.4rem;
      text-align: center;
    }
  }
}

.hgift {
  &-flex {
    display: flex;
    @include max(850px) {
      flex-direction: column-reverse;
    }
  }
  &-left {
    width: 50%;
    @include max(850px) {
      width: 100%;
    }
  }
  &-right {
    width: 50%;
    @include max(850px) {
      width: 100%;
    }
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    @include max(850px) {
      display: flex;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &-item {
    position: relative;
    padding: 2.4rem;
    border: 0.1rem solid $color-pri;
    background: #f9f7f5;
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    &:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    &:nth-child(3) {
      grid-area: 2 / 1 / 3 / 2;
    }
    &:nth-child(4) {
      grid-area: 2 / 2 / 3 / 3;
    }
    @include hover {
      .hgift-desc {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
      .hgift-img {
        img {
          transform: translate(-50%, -50%) scale(1.05);
        }
      }
    }
  }
  &-img {
    padding-top: 100%;
    overflow: hidden;
    position: relative;
    display: block;

    @include max(850px) {
      padding-top: calc((220 / 172) * 100%);
    }

    img {
      @include imgCover;
      transition: 0.4s linear;
    }
  }
  &-desc {
    position: absolute;
    bottom: 2.4rem;
    left: 2.4rem;
    transition: 0.3s ease-in-out;
    transform: translateY(1rem);
    opacity: 0;
    visibility: hidden;
    .link {
      font-weight: 600;
    }
    @include max(1024px) {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      position: relative;
      left: 0;
      bottom: 0;
      margin-top: 0.8rem;
    }
  }
  .price {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .current {
      font-weight: 600;
      white-space: nowrap;
    }
    .old {
      color: #807d7c;
      @include fz-12;
      font-weight: 500;
      white-space: nowrap;
      text-decoration: line-through;
    }
  }

  &-slide {
    height: 100%;
    width: 100%;
    .swiper {
      height: 100%;
      &-slide {
        height: auto !important;

        &-active {
          .hgift-box-desc {
            .title,
            .link {
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
          }
        }
      }
    }
  }

  &-box {
    position: relative;
    height: 100%;
    width: 100%;

    &-img {
      display: flex;
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-desc {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        inset: 0;
        background: linear-gradient(
          180deg,
          rgba(31, 29, 29, 0) 15.17%,
          rgba(12, 10, 10, 0.4) 87.09%
        );
      }

      .title {
        color: white;
        font-size: 4.4rem;
        font-weight: 700;
        line-height: 1.2;
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
        transition: 0.7s ease-in-out;
        @include max(768px) {
          font-size: 3.6rem;
        }
      }
      .link {
        color: white;
        text-decoration: underline;
        font-size: 1.6rem;
        font-weight: 600;
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
        transition: 1.3s ease-in-out;
      }
    }
  }

  &-btn {
    position: absolute;
    bottom: 2.4rem;
    right: 2.4rem;
    display: flex;
    flex-direction: row-reverse;
    gap: 0.8rem;
    @include max(800px) {
      display: none;
    }

    &-next,
    &-prev {
      position: relative;
      inset: 0;
      margin: 0 !important;
      width: 4rem;
      height: 4rem;
      border: 0.1rem solid white;
      border-radius: 100rem;

      &:after {
        display: none;
      }

      i {
        color: white;
        font-size: 1.6rem;
      }
    }
  }
}

.hab {
  &-flex {
    display: flex;
    border-bottom: 0.1rem solid $color-pri;
    @include max(768px) {
      flex-direction: column;
    }
  }
  &-left {
    width: 50%;
    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    width: 50%;
    @include max(768px) {
      width: 100%;
    }
  }
  &-ctn {
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding: 2rem 10rem;
    background: #f9f7f5;

    @include max(1200px) {
      padding: 2rem;
    }
    @include max(768px) {
      padding: 6rem 1.5rem;
    }

    .title {
      font-size: 4.4rem;

      @include max(768px) {
        font-size: 3.6rem;
      }
    }
    .btn {
      margin-top: 2.4rem;
    }
  }
  &-item {
    position: relative;
  }
  &-img {
    display: block;
    padding-top: calc((800 / 720) * 100%);
    overflow: hidden;
    position: relative;

    img {
      transition: 0.3s ease-in-out;
      @include imgCover;
    }
    @include hover {
      img {
        transform: translate(-50%, -50%);
      }
    }
  }
  &-text {
    position: absolute;
    left: 2.4rem;
    bottom: 2.4rem;
    font-weight: 500;
    width: calc((100% - 10rem));
    @include max(768px) {
      left: 2rem;
      bottom: 2rem;
    }
  }
  &-link {
    width: 6rem;
    height: 6rem;
    border-radius: 100rem;
    border: 0.2rem solid white;
    @include mid-flex;
    position: absolute;
    right: 2.4rem;
    bottom: 2.4rem;

    @include max(768px) {
      bottom: 2rem;
      right: 2rem;
    }

    i {
      font-size: 3rem;
      color: white;
      transform: rotate(-45deg);
      transition: 0.3s ease-in-out;
    }
    @include hover {
      i {
        transform: rotate(0);
      }
    }
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2023-11-16 16:45:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
.bounce-in-top {
  -webkit-animation: bounce-in-top 1.1s both;
  animation: bounce-in-top 1.1s both;
}
@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-16 16:43:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-hor-left
 * ----------------------------------------
 */
.scale-in-hor-left {
  -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-16 16:41:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-center
 * ----------------------------------------
 */
.scale-in-ver-center {
  -webkit-animation: scale-in-ver-center 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}
@keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-16 13:51:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
