.qtban {
  &-wrap {
    position: relative;

    .container {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 17.1%,
        rgba(0, 0, 0, 0.8) 100%
      );
    }
  }
  &-inner {
    height: calc(100vh - var(--size-hd));
    padding: 10rem 0;
    padding-top: 8rem;
    display: flex;
    align-items: center;

    @include max(768px) {
      padding: 4rem 0;
    }
  }
  &-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .title {
      font-size: 10rem;
      line-height: 1;
      text-align: center;
      @include max(768px) {
        font-size: 5.8rem;
      }
      @include max(360px) {
        font-size: 5rem;
      }
    }
    .desc {
      margin: 0 auto;
      width: 55%;
      @include max(1100px) {
        width: 80%;
      }
    }
  }
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: -2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-btn {
    margin-top: 2.4rem;
    .btn-second {
      border-color: white !important;

      .text {
        color: white;
      }
      @include hover {
        .text {
          color: black;
        }
      }
    }
  }
}

.about {
  &-wrap {
    padding-left: 4rem;
    background: #f9f7f5 $color-pri;
    border-bottom: 0.1rem solid;
    @include max(1200px) {
      padding-left: 1.5rem;
    }
    @include max(768px) {
      padding: 0;
    }
  }
  &-inner {
    padding-left: calc((100vw - 192rem) / 2);
  }
  &-flex {
    display: flex;
    @include max(768px) {
      flex-direction: column;
    }
  }
  &-left {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 8rem;
    padding-left: 4rem;

    .title {
      font-size: 5.2rem;
      line-height: 1.2;
      margin-bottom: 1rem;
    }
    .link {
      @include fz-16;
      font-weight: 600;
      text-decoration: underline;
      display: flex;
      margin-top: 2rem;
    }

    @include max(1100px) {
      width: 40%;
      padding-right: 2rem;
      padding-left: 0;
    }
    @include max(768px) {
      width: 100%;
      padding: 6rem 1.5rem;
    }
  }
  &-right {
    flex: 1;
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  &-item {
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 3;
      .box {
        padding-top: calc((460 / 720) * 100%);
        @include max(768px) {
          padding-top: calc((250 / 375) * 100%);
        }
      }
    }
    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
      .box {
        padding-top: calc((460 / 360) * 100%);
        @include max(768px) {
          padding-top: calc((250 / 187) * 100%);
        }
      }
    }
    &:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
      .box {
        padding-top: calc((460 / 360) * 100%);
        @include max(768px) {
          padding-top: calc((250 / 187) * 100%);
        }
      }
    }
  }
  &-ctn {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 2.4rem;
    gap: 0.4rem;
    left: 0;
    bottom: 0;
    z-index: 3;
    background: linear-gradient(
      180deg,
      rgba(31, 29, 29, 0) 37.1%,
      rgba(12, 10, 10, 0.4) 87.09%
    );

    .title {
      font-size: 7.4rem;
      line-height: 1;
    }
    .desc {
      color: #e5ded8;
      font-size: 1.6rem;
    }

    @include max(1100px) {
      padding: 3rem 1.6rem;
      .title {
        font-size: 4.8rem;
      }
    }
  }
  &-img {
    position: relative;
    .box {
      position: relative;
      overflow: hidden;

      img {
        @include imgCover;
        transition: 0.4s ease-in-out;
      }
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }
}

.pack {
  &-top {
    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.8rem;
      padding: 10rem 0;
      margin: 0 auto;
      width: 59%;

      @include max(1200px) {
        width: 80%;
        padding: 8rem 0;
      }
      @include max(768px) {
        width: 100%;
        padding: 6rem 0;

        .title {
          font-size: 4.4rem !important;
        }
      }

      .title {
        font-size: 5.2rem;
        line-height: 1;
      }
      .text {
        @include fz-20;
      }
      .btn {
        margin-top: 1.6rem;
      }
    }
  }
  &-inner {
  }
  &-flex {
    display: flex;
    padding-bottom: 10rem;
    &:nth-child(even) {
      flex-direction: row-reverse;
      padding-left: calc((100vw - 192rem) / 2);

      .pack-img {
        &:nth-child(2) {
            width: 42.85%;
          .box {
            padding-top: calc((420 / 360) * 100%);
          }
        }
        &:nth-child(1) {
            flex: 1;
          .box {
            padding-top: calc((420 / 480) * 100%);
          }
        }
      }
    }
    &:nth-child(odd) {
      padding-right: calc((100vw - 192rem) / 2);

      .pack-img {
        &:nth-child(1) {
            width: 42.85%;
          .box {
            padding-top: calc((420 / 360) * 100%);
          }
        }
        &:nth-child(2) {
            flex: 1;
          .box {
            padding-top: calc((420 / 480) * 100%);
          }
        }
      }
    }
    @include max(992px) {
        padding-bottom: 6rem;
    }
    @include max(768px) {
        flex-direction: column !important;
        gap: 2.4rem;
    }
  
  }
  &-left {
    display: flex;
    width: calc((7 / 12) * 100%);
    @include max(1100px) {
        width: 50%;
    }
    @include max(768px) {
        width: 100%;
    }
  }
  &-right {
    padding: 0 8rem;
    flex: 1;
    @include max(1250px) {
        padding:0 4rem;
    }
    @include max(1200px) {
        padding:0 1.5rem;
    }
  }
  &-desc {
    &-flex {
      display: flex;
      gap: 0.5rem;
    }
    &-left {
      width: max-content;
      flex-shrink: 0;
      .text {
        color: #c75d00;
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 1.2;
        margin-top: 0.8rem;
        display: flex;
      }
    }
    &-right {
      flex: 1;

      .title {
        font-size: 5.2rem;
        line-height: 1.2;
        margin-bottom: 1.2rem;

        @include max(992px) {
            font-size: 4.4rem;
        }
      }
      .link {
        text-decoration: underline;
        display: flex;
        margin-top: 1.2rem;
      }
    }
  }
  &-img {
    .box {
      position: relative;
      overflow: hidden;

      img {
        @include imgCover;
      }
    }
  }
}

.packpro {
    padding: 10rem 0;
    border-top: 0.1rem solid $color-pri;
    border-bottom: 0.1rem solid $color-pri;
    overflow: hidden;


    @include max(768px) {
        padding: 6rem 0;
    }
    &-top {
        &-flex {
            display: flex;
            align-items:center;
            justify-content:space-between;
            gap: 2rem;flex-wrap: wrap;
        }
        &-left {
            max-width: 64rem;
            .title {
                font-size:5.2rem;
                line-height: 1.2;
            }
        }
        &-right {
            display: flex;
            align-items:center;
            gap: 2.4rem;
        }
    }
    &-inner {
        padding-left: 4rem;
        margin-top: 2.4rem;

        @include max(1200px) {
            padding-left:1.5rem;
        }
    }
    &-slide {
        padding-left:calc((100vw - 192rem) / 2);

    }
    &-item {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }
    &-img {
        .box {
            padding-top: calc((400/300) * 100%);
            position: relative;
            overflow: hidden;
            display: block;

            img {
                @include imgCover;
                transition: 0.4s ease-in-out;
            }
        }
        @include hover {
            img {
                transform: translate(-50%,-50%) scale(1.05);
            }
        }
    }
    &-desc {
        .name {
            font-weight:600;
            display: block;
        }
        .desc {
            color: #807D7C;
            font-weight:500;
        }
    }
    &-btn {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        gap: 0.8rem;
        @include max(768px) {
            display:none;
        }

        &-next,
        &-prev {
            position: relative;
            inset: 0;
            margin: 0 !important;
            width: 4rem;
            height: 4rem;
            border: 0.1rem solid black;
            border-radius:100rem;

            &:after {
                display: none;
            }
            
            i {
                color: black;
            }
        }
    }
    .swiper {
        &-wrap {
            margin: 0 -0.4rem;
        }
        &-slide {
            width:21.5%;
            padding: 0 0.4rem;

            @include max(992px) {
                width:30%;
            }
            @include max(768px) {
                width:40%;
            }
            @include max(650px) {
                width: 55%;
            }
            @include max(550px) {
                width: 63%;
            }
        }
    }
}

.sgaller {
    padding: 8rem 0;
    padding-bottom: 4rem;
    @include max(768px) {
        padding: 6rem 0;
        padding-bottom:2rem;
    }
    .title {
        font-size:5.2rem; 
        padding: 0 1.5rem;
        @include max(768px) {
            font-size:4.4rem;
            text-align: left;
        }
    }
    &-img {
        height:30rem;

        @include max(768px) {
            height:28rem;
        }

        img {
            object-fit: contain;
            height: 100%;
        }
    }
    .swiper {
        &-wrapper {
            transition-timing-function:linear !important;
        }
        &-slide {
            width:max-content;
            height: auto;
        }
    }
}

.sab {
    border-top: 0.1rem solid $color-pri;
    &-flex {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        @include max(768px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &-col {
        &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2; 
        }
        &:nth-child(2) {
            grid-area: 1 / 2 / 2 / 3;  
        }
        &:nth-child(3) {
            grid-area: 1 / 3 / 2 / 4;
        }
        &:nth-child(4) {
            grid-area: 2 / 1 / 3 / 2;
        }
        &:nth-child(5) {
            grid-area: 2 / 2 / 3 / 4;
        }
        @include max(768px) {
            &:nth-child(1) {
                width: 100%;
            }
            &:nth-child(2) {
                width: 50%;
            }
            &:nth-child(3) {
                width: 50%;
            }
            &:nth-child(4) {
                width: 100%;
                .sab-img {
                    padding-top: calc((164/375) * 100%);
                }
            }
            &:nth-child(5) {
                width: 100%;
            }
        }
    }
    &-img {
        position: relative;
        padding-top: 100%;
        overflow: hidden;

        img {
            @include imgCover;
        }
    }
    &-desc {
        background: $color-pri;
        height: 100%;
        @include mid-flex;
        padding: 2rem 12rem;
        @include max(1100px) {
            padding: 1rem 6rem
        }
        @include max(768px) {
            padding:6rem 2rem;
        }
    }
    &-box {
        @include mid-flex;
        height: 100%;
        flex-direction: column;
        gap: 1.6rem;
        padding: 1rem 4rem;

        @include max(1100px) {
            padding: 1rem 1.5rem;
        }
        @include max(768px) {
            padding: 6rem 1.5rem;
        }

        .icon {
            width:8.4rem;
            height:8.4rem;

            img {
                width: 100%;
                object-fit: contain;
            }
        }
        .title {
            font-size:4.4rem;
            line-height: 1.2;
        }
        @include max(992px) {
            .title {
                font-size:3.6rem;
            }
        }
    }
}
.ser {
    overflow: hidden;
    padding: 10rem 0;
    @include max(768px) {
        padding: 6rem 0 ;
    }
    &-flex {
        display: flex;
        margin: 0 -4rem;
        @include max(1200px) {
            margin: 0 -2rem;
        }
        @include max(992px) {
            margin: 0 -0.5rem;
        }
        @include max(768px) {
            flex-direction: column;
        }
    }
    &-left {
        width: calc((5/12) * 100%);
        padding:0 4rem;
        @include max(1200px) {
            padding: 0 2rem;
        }
        @include max(992px) {
            padding: 0 0.5rem;
            width: 50%;
        }
        @include max(768px) {
            width: 100%;
        }

        .desc {
            color: #474140;

        }
        .title {
            font-size:7.4rem;
            line-height: 1;
        }
        .btn {
            margin-top: 1.6rem;
        }
        @include max(992px) {
            .title {
                font-size:4.8rem;
                br {
                    display: none;
                }
            }
        }
    }
    &-right {
        flex: 1;
        padding: 0 4rem;
        @include max(1200px) {
            padding: 0 2rem;
        }
        @include max(992px) {
            padding: 0 0.5rem;

        }
    }
    &-ctn {
        padding: 0 4rem;
        @include max(1200px) {
            padding: 0;
        }
    }
    &-item {
        padding: 4rem 2.4rem;
        border-bottom:0.1rem solid $color-pri;
        .title {
            font-size: 4.4rem;
            line-height: 1.2;
        }
        &:last-child {
            border-bottom: none;
        }
        @include max(768px) {
            padding: 4rem 0;
        }
    }
    &-desc {
        display: flex;
        gap: 2.4rem;
        justify-content:space-between;
        .icon {
            width: 3.5rem;
            height:3.5rem;
            @include mid-flex;

            img {
                object-fit: contain;
                width: 100%;
            }
        }
        .text {
            flex: 1;
            @include min(1200px) {
                max-width: 50%;
            }
        }
    }
}
