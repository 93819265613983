.pro {
  &-box {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &.option-2 {
      .pro-img {
        &:not(.open) {
          display: none;
        }
      }
    }
  }
  &-img {
    position: relative;
    overflow: hidden;
    .box {
      padding-top: calc((400 / 300) * 100%);
      position: relative;
      overflow: hidden;
      display: block;

      img {
        @include imgCover;
        transition: 0.3s ease-in-out;
      }
    }
    @include hover {

      .pro-add-pc {
        transform: translate(-50%, 0);
        opacity: 1;
      }
      .box-pc {
        display: none;
        &:nth-child(2) {
            display:block;
        }
      }
    }



    .box-pc {
        &:nth-child(2) {
            display:none;
        }

        @include max(1024px) {
            display: none !important;
        }
    }

    .swiper {
        @include min(1024.99px) {
            display: none;
        }
        &-pagination {
            @include min(1024.99px) {
                display:none;
            }
            display: flex;
            padding-left: 0.8rem;

            &-bullet {
                margin: 0 !important;
                width:1.5rem;
                border-radius: 0;
                height: 0.2rem;

                &-active {
                    background: $color-pri;
                }
            }
        }
    }
  }
  &-desc {
    display: flex;
    flex-direction: column;

    &-op {
      margin-top: auto;
    }

    .desc {
      margin-bottom: 0.8rem;
      color: #807d7c;
    }
  }
  &-name {
    font-weight: 600;
    transition: 0.3s ease-in-out;
    margin-bottom: 0.8rem;

    @include hover {
      color: rgb(98, 98, 98);
    }
  }
  &-price {
    color: #474140;
    margin-bottom: 0.8rem;
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;
    align-items: center;

    .dis {
      font-size: 1.2rem;
      color: #807d7c;
    }
    .price-new {
      font-weight: 500;
      color: #474140;
    }
    .price-odd {
      font-size: 1.2rem;
      color: #807d7c;
      text-decoration: line-through;
    }
  }
  &-tag {
    position: absolute;
    top: 0;
    left: 0;
    background: #eb2f3a;
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
    padding: 0.4rem 0.8rem;
    width: max-content;
    z-index: 4;
  }
  &-add {
    height: 4rem;
    border-radius: 10rem;
    @include mid-flex;
    gap: 0.8rem;
    border-radius: 10rem;
    border: 0.1rem solid $color-grey-3;
    background: #fff;
    padding: 0.5rem 1.6rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    .icon {
      display: flex;
      align-items: center;
      width: 2.4rem;
      height: 2.4rem;
    }
    .text {
      line-height: 1;
      font-weight: 600;
    }
    @include hover {
      background: #faf6f4;
    }

    &-pc {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translate(-50%, 100%);
      width: max-content;
      opacity: 0;
      z-index: 4;

      @include max(1024px) {
        display: none;
      }
    }
    &-mb {
      margin-top: 0.8rem;
      display: none;

      .text {
        text-align:center;
      }

      @include max(1024px) {
        display: flex;
      }
    }
  }
}
.pro-item {
  .recheck {
    &-item {
      &.active {
        .recheck-checkbox {
          border: 0.1rem solid black;

          &::before {
            display: none;
          }
        }
      }
    }
    &-checkbox {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 100rem;
      padding: 0.3rem;
      border: none;
      background: none !important;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100rem;
      }
    }
    &-block {
      display: flex;
      gap: 0.5rem;
    }
  }
}
