.footer {
  padding: 8rem 0;
  padding-bottom: 0;
  background: #fff;
  border-top: 1px solid $color-pri;


  @include max(768px) {
    padding: 4rem 0;
    padding-bottom: 0;
  }

  &-wrap {
    position: relative;
  }
  &-flex {
    display: flex;
    margin: 0 -1.2rem;
    margin-bottom: 3rem;
    justify-content: space-between;

    @include max(1200px) {
      flex-wrap: wrap;
      row-gap: 2rem;
    }
    @include max(768px) {
      margin-bottom: 4rem;
    }
  }
  &-col {
    padding: 0 1.2rem;
    font-size: 1.2rem;

    .menu-list {
      .menu-item {
        margin-bottom: 0.8rem;

        .menu-link {
          width: max-content;
          display: block;
        }
      }
    }

    &:nth-child(1) {
    }

    &:nth-child(2) {
    }
    &:nth-child(3) {
    }
    &:last-child {
      width: calc((3.5/12) * 100%);
      @include max(1200px){
        width: 50%;
      }

      @include max(768px){
        width: 100%;
      }
    }

    @include max(768px) {
      width: 100%;
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: none;
      }
      &:nth-child(4) {
        display: none;
      }
      &:nth-child(5) {
        order: 3;
        .footer-title {
          display: none;
        }
      }
      &:nth-child(6) {
        order: 1;
      }
    }
  }
  &-title {
    font-weight: 600;
    margin-bottom: 1.2rem;
  }

  &-info {
    font-size: 1.2rem;

    &:nth-child(2) {
      margin-top: 2.4rem;
    }

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      gap: 0.5rem;

      .icon {
        display: flex;
        width:1.6rem;
        height:1.6rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

      }

      .text {
        font-weight: 600;
      }
    }
    &-link {
      display: block;
      width: max-content;
      margin-bottom: 0.8rem;
    }
  }

  &-form {
    > .text {
      @include fz-14;
      font-weight: 600;
      margin-bottom: 0.8rem;
    }
    .pcontact-form-list {
      display: flex;
      gap: 0.8rem;
      flex-wrap: wrap;

      .ip-control {
        height: 4rem;
        input {
          width: 100%;
          height: 100%;
          border: 1px solid #f3edea;
          background: #f9f7f5;
          font-size: 1.2rem;
          padding: 0 1.2rem;

          &:focus {
            outline: none;
            border-color: black;
          }
        }
        .select2-container {
          width: 100% !important;
          height: 100%;
          font-size: 1.2rem;
        }
        .select2-container .select2-selection--single,
        .select2-selection__rendered {
          height: 100%;
          display: flex;
          align-items: center;
          background: #f9f7f5;
          border-radius: 0;
          width: 100%;
          border-color: transparent;
        }
        .select2-selection__rendered {
          border: 1px solid #f3edea;
        }
        .select2-selection__arrow {
          height: 100% !important;
        }
        &:nth-child(1) {
          width: calc(100% / 3);
        }
        &:nth-child(2) {
          flex: 1;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
    .pcontact-form-btn {
      margin-top: 2rem;
      .btn {
        height: 4rem;
        width: 100%;
      }
    }
  }

  &-social {
    display: flex;
    gap: 1.6rem;
    margin-top: 1rem;

    &-link {
      width: 2.8rem;
      height: 2.8rem;
      @include mid-flex;

      img {
        width: 100%;
        object-fit: contain;
        transition: 0.3s ease-in-out;
      }
      @include hover {
        img {
          transform: translateY(-0.5rem);
        }
      }
    }
  }

  &-bot {
    padding: 3rem 0;
    @include max(768px) {
      padding: 2rem 0;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 100vw;
        height: 0.2rem;
        background: rgba(128, 125, 124, 0.3);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &-flex {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      @include max(650px) {
        flex-direction: column;
      }
    }
    &-left {
      flex: 1;

      @include min(992px) {
        width: 35%;
        flex: unset;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }
  }
  &-ct {
    height: 3.2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-mt {
    display: flex;
    gap: 0.8rem;
    &-item {
      height: 2.4rem;
      width: 2.4rem;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.fsocial {
  position: fixed;
  bottom: 13rem;
  right: 3rem;
  z-index: 55;

  @include max(1200px) {
    bottom: 9rem;
  }

  @include max(768px) {
    right: 1.5rem;
  }
  transition: 0.5s linear;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .icon {
    height: 8rem;
    width: 8rem;
    display: flex;
    margin-top: 2rem;
    transition: 0.3s ease-in-out;
    padding: 2rem 0.8rem;
    position: relative;
    border: 0.2rem solid white;
    border-radius: 100rem;
    background: white;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @include max(1200px) {
      width: 4rem;
      height: 4rem;
      padding: 1rem 0.5rem;
    }

    img {
      object-fit: contain;
    }

    .circlejs {
      animation: growAndFade 3s infinite ease-out;
      background-color: dodgerblue;
      border-radius: 50%;
      opacity: 0;
      position: absolute;
      height: 6.7rem;
      width: 6.7rem;
      left: 50%;
      top: 50%;
      z-index: -1;
    }
    .circle1 {
      animation-delay: 1s;
    }
    .circle2 {
      animation-delay: 2s;
    }
    .circle3 {
      animation-delay: 3s;
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 0;
      border-radius: 100rem;
    }
    @include hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
      height: auto;
      -webkit-animation: shake-lr 4s linear infinite both;
      animation: shake-lr 4s linear infinite both;
    }

    //   &:nth-child(1) {
    //     &:before {
    //       background-color: $color-pri;
    //     }
    //     .circlejs {
    //       background-color: #fff;
    //     }
    //   }
    //   &:nth-child(2) {
    //     &:before {
    //       background-color: rgba(0, 104, 255, 0.2);
    //     }
    //     .circlejs {
    //       background-color: rgba(0, 104, 255);
    //     }
    //   }
  }
}

@-webkit-keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
}
@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-22 14:11:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
.shake-vertical {
  -webkit-animation: shake-vertical 4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    infinite both;
  animation: shake-vertical 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
    both;
}
@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
