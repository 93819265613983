.aban {
  &-wrap {
    position: relative;

    .container {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 17.1%,
        rgba(0, 0, 0, 0.8) 100%
      );
    }
  }
  &-inner {
    height: calc(100vh - var(--size-hd));
    padding: 10rem 0;
    padding-top: 8rem;
    display: flex;
    align-items: flex-end;

    @include max(768px) {
      padding: 4rem 0;
    }
  }
  &-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .text {
      @include fz-16;
    }
    .title {
      font-size: 7.4rem;
      line-height: 1;
      text-align: center;
      @include max(768px) {
        font-size: 4.8rem;
      }
    }
    .desc {
      margin: 0 auto;
      width: 55%;
      @include max(1100px) {
        width: 80%;
      }
    }
  }
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: -2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-flex {
    display: flex;
    @include max(768px) {
      flex-direction: column !important;
    }

    &:nth-child(odd) {
      .aban-left {
        width: 32.5%;
      }
      .aban-img {
        .box {
          padding-top: calc((546 / 960) * 100%);
        }
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .aban-left {
        width: 50%;
      }
      .aban-img {
        .box {
          padding-top: calc((546 / 720) * 100%);
        }
      }
    }
  }
  &-left {
    display: flex;
    align-items: center;
    padding: 1rem 4rem;

    @include max(1100px) {
      padding: 2rem 1.5rem;
    }
    @include max(768px) {
      width: 100% !important;
      padding: 6rem 1.5rem;
    }
  }
  &-right {
    flex: 1;
  }
  &-img {
    .box {
      position: relative;
      overflow: hidden;

      @include max(650px) {
        padding-top: calc((320 / 375) * 100%) !important;
      }

      img {
        @include imgCover;
        transition: 0.3s ease-in-out;
      }
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }
  &-desc {
    .title {
      font-size: 5.2rem;
      line-height: 1.2;
    }
  }
}

.apart {
  &-wrap {
    padding: 2rem 0;
    border-top: 1px solid $color-pri;
    border-bottom: 1px solid $color-pri;
    background: #ecebea;

    @include max(768px) {
      padding: 1.5rem 0;
    }
  }

  &-text {
    font-size: 3.2rem;
    font-weight: 700;
  }

  .swiper {
    &-wrapper {
      transition-timing-function: linear !important;
    }
    &-slide {
      width: max-content;
    }
  }
}
.svalu {
  padding: 8rem 0;
  @include max(768px) {
    padding: 6rem 0;
  }
  &-flex {
    display: flex;
    margin: 0 -2rem;

    @include max(650px) {
      flex-direction: column;
      row-gap: 4rem;
    }
  }
  &-left {
    padding: 0 2rem;
    width: 50%;
    flex-direction: column;
    @include mid-flex;
    gap: 0.8rem;
    @include max(650px) {
      width: 100%;
    }

    .title {
      font-size: 7.4rem;
      line-height: 1;
      @include max(768px) {
        font-size: 4.8rem;
      }
    }
  }
  &-right {
    flex: 1;
    padding: 0 2rem;
  }
  &-img {
    .box {
      position: relative;
      overflow: hidden;
      padding-top: calc((640 / 660) * 100%);

      img {
        @include imgCover;
        transition: 0.4s ease-in-out;
      }
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }
}
.histo {
  &-top {
    border-top: 0.1rem solid #e5ded8;
    border-bottom: 0.1rem solid #e5ded8;
    background: #ecebea;
    @include mid-flex;
    position: sticky;
    top: calc(var(--size-hd));
    z-index: 1;

    &-list {
      display: flex;
    }

    &-item {
      font-size: 3.6rem;
      font-weight: 600;
      padding: 2rem 6rem;
      color: #807d7c;
      height: 10rem;
      display: flex;
      position: relative;
      transition: 0.3s ease-in-out;
      line-height: 1;
      align-items: center;

      @include max(768px) {
        height:8rem;
        padding: 2rem 3rem;
        font-size: 2.8rem;
      }

      &::before {
        position:absolute;
        content: "";
        left:50%;
        transform: translateX(-50%);
        bottom:0;
        height: 0.1rem;
        width: 0;
        background-color: $color-pri;
        transition: 0.3s ease-in-out;
      }

      &.active {
        color: $color-pri;
        &:before { 
            width: 100%;
        }
      }
    }
  }
  &-ctn {
    padding: 2rem 0;
  }
  &-item {
    padding: 6rem 0;
    scroll-margin-block-start: calc(var(--size-hd) + 6rem);

    @include max(1100px) {
        padding:3rem 0;
    }


    &:nth-child(1) {
      .histo-img {
        .box {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
            padding-top: 100%;
          }
          &:nth-child(2) {
            grid-area: 1 / 2 / 2 / 3;
            padding-top: 100%;
          }
          &:nth-child(3) {
            grid-area: 2 / 1 / 3 / 3;
          }
        }
      }
    }
    &:nth-child(2) {
      .histo-img {
        .box {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 3;
          }
          &:nth-child(2) {
            grid-area: 2 / 1 / 3 / 2;
            padding-top: 100%;
          }
          &:nth-child(3) {
            grid-area: 2 / 2 / 3 / 3;
            padding-top: 100%;
          }
        }
      }
    }
    &:nth-child(3) {
      .histo-flex {
        flex-direction: row-reverse;
      }
      .histo-img {
        .box {
          &:nth-child(1) {
            grid-area: 1 / 1 / 3 / 2;
          }
          &:nth-child(2) {
            grid-area: 1 / 2 / 2 / 3;
            padding-top: 100%;
          }
          &:nth-child(3) {
            grid-area: 2 / 2 / 3 / 3;
            padding-top: 100%;
          }
        }
      }
    }
    &:nth-child(4) {
        .histo-flex {
          flex-direction: row-reverse;
        }
        .histo-img {
          .box {
            padding-top: 100%;

            &:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2; 
            }
            &:nth-child(2) {
                grid-area: 1 / 2 / 2 / 3;
            }
            &:nth-child(3) {
                grid-area: 2 / 1 / 3 / 2;
            }
            &:nth-child(4) {
                grid-area: 2 / 2 / 3 / 3; 
            }
          }
        }
      }
  }
  &-img {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0.8rem;
    grid-row-gap: 0.8rem;
    .box {
      position: relative;
      overflow: hidden;

      img {
        @include imgCover;
        transition: 0.4s ease-in-out;
      }
      @include hover {
        img {
          transform: translate(-50%, -50%) scale(1.05);
        }
      }
    }
  }
  &-flex {
    display: flex;
    margin: 0 -2rem;
    @include max(1100px) {
        margin: 0 -1rem;
    }
    @include max(650px) {
        flex-direction: column-reverse !important;
        row-gap: 4rem;
    }
  }
  &-left {
    width: 50%;
    padding: 0 2rem;
    @include max(1100px) {
        padding: 0 1rem;
    }
    @include max(650px) {
        width: 100%;
    }
  }
  &-right {
    flex: 1;
    padding: 0 2rem;
    @include max(1100px) {
        padding: 0 1rem;
    }

    .title {
      font-size: 10rem;
      line-height: 1;
    }
  }
}

.slogan {
  &-inner {
    background-color: #ECEBEA;
    padding: 6rem 8rem;
    @include max(1200px) {
      padding: 6rem 3rem;
    }
    @include max(768px) {
      padding: 0 3.2rem;
    }
  }
  &-list {
    display:flex;
    margin: 0 -8rem;
    @include max(1200px) {
      margin: 0 -3.2rem;

    }
    @include max(768px) {
      flex-direction: column;
      margin:0;
    }
  }
  &-item {
    width:calc(100% / 3);
    padding: 0 8rem;
    border-right: 0.1rem solid $color-pri;
    @include max(1200px) {
      padding: 0rem 3.2rem;
    }
    @include max(768px) {
      width: 100%;
      border-right: none;
      border-bottom: 0.1rem solid $color-pri;
      padding: 6rem 0;

      &:last-child {
        border-bottom: none;
      }

      .title {
        font-size:3.6rem !important;
        color: #000;
      }
    }

    .icon {
      width: 5rem;
      height: 5rem;
      display: flex;
      margin-bottom: 1.6rem;
      img {
        width:100%;
        height: 100%;object-fit: contain;
      }
    }

    .title {
      @include fz-36;
      line-height: 1.2;
      margin-bottom: 0.8rem;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.smore {
  padding: 8rem 0;
  @include max(768px) {
    padding: 6rem 0;
  }
  &-inner {
    .title {
      font-size:4.4rem;
      margin-bottom: 2.4rem;
    }
  }
  &-list {
    display: flex;
    margin: 0 -0.4rem;

    @include max(768px){
      flex-direction: column;
      row-gap: 0.8rem;
    }

  }
  &-item {
    position: relative;
    width: calc(100% / 3);
    padding: 0 0.4rem;
    @include max(768px) {
      width: 100%;
    }
  }
  &-ctn {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 2.4rem;
    gap: 0.4rem;
    left: 0;
    bottom: 0;
    z-index: 3;
    background: linear-gradient(180deg, rgba(31, 29, 29, 0.00) 37.1%, rgba(12, 10, 10, 0.40) 87.09%);

    .link {
      text-decoration: underline;
    }

  }
  &-img {
    position: relative;
    .box{
      position: relative;
      overflow: hidden;
      padding-top: 100%;

      img {
        @include imgCover;
        transition: 0.4s ease-in-out;
      }
   
    }
    @include hover {
      img {
        transform: translate(-50%,-50%)scale(1.05);
      }
    }
  }
}