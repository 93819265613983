// @import url("../assets/font/Themify/stylesheet.css");
// @import url("../assets/font/Roboto/stylesheet.css");
@import url("../assets/font/Monsterrat/stylesheet.css");
@import url("../assets/font/Open-sans/stylesheet.css");
@import url("../assets/font/Poppins/stylesheet.css");
@import url("../assets/font/Alumni/stylesheet.css");





* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: 'Montserrat'; 
    font-size: 14px;
    line-height: 1.55;
    font-weight: 400;
    color: #1A1A1A;
    overflow: hidden auto;

    @media screen and (min-width: 2000px) {
        font-size: 20px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;

}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    .text {
    font-family: 'Montserrat'; 

    }
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Montserrat'; 

}

.main,
.site-main {
    // min-height: 68vh;
    padding-top: var(--size-hd);
}

.container {
    width: 100%;
    max-width: 192rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 4rem;

   @media only screen and (max-width:1200px) {
    padding: 0 1.5rem;
   }
}



.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}


img {
    max-width: 100%;
    height: auto;
}