.pcollec {
    &-flex {
        display: flex;
    }
    &-left {
        width: calc((2/12) * 100% + 0.1rem);
        padding: 1.2rem 0;
        padding-right: 2rem;
        @include max(1200px) {
            width: calc((3/12) * 100%);
        }
        @include max(768px) {
            display: none;
        }
    }
    &-right {
        width: calc((10/12) * 100% - 0.1rem);
        padding: 2rem 4rem;
        padding-right: 0;
        border-left: 1px solid  #1A1A1A;


        @include max(1200px) {
            width: calc((9/12) * 100%);
            padding: 2rem;
            padding-right: 0;
        }
        @include max(768px) {
            width: 100%;
            padding: 2rem 0;
            border: none;
        }

    }

    &-cate {
        &-item {
            // &:not(:first-child) {
            //     .pcollec-cate-body {
            //         display:none;
            //     }
            // }
        }
        &-head {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 0.8rem 0;

            .text {
                font-weight: 600;
            }
    
            .icon {
                i {
                    font-size: 1.6rem;
                    font-weight:500;
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }

            &.active {
               
                .icon {
                    i {
                        display: none;
                        &:nth-child(2) {
                            display: block;
                        }
                    }
                }

                // ~.pcollec-cate-body  {
                //     display: block;
                // }
            }
        }
        &-body {
            display: none;
            >.scate-list {
                padding-left: 2rem;
            }
        }
    }

    &-main {
        padding-top: 1.4rem;
    }

    &-list {
        display: flex;
        margin: 0 -0.4rem;
        row-gap: 2.4rem;
        flex-wrap: wrap;

        .pro-item {
            width: 25%;
            padding: 0 0.4rem;
        }

        &-btn {
            margin-top: 2.4rem;
            .btn { 
                margin: 0 auto;
            }
        }

        @include max(1200px) {
            .pro-item {
                width: calc(100% / 3);
            }
        }

        @include max(768px) {
            .pro-item {
                width: 50%;
            }
        }
    }

    &-fill {
        margin-top: 1.4rem;
        &-flex {
            display: flex;
            justify-content:space-between;
            gap: 1rem;
            align-items: center;
            position: relative;

        }
        &-left {
            flex: 1;
            display: flex;
            align-items:center;
            gap: 2rem;

        }
        &-right {
            width: max-content;

        }
    }
    &-fi {
        &-top {
            display: flex;
            align-items:center;
            gap: 0.4rem;
            cursor: pointer;
            padding: 1rem 0;

            .icon {
                width: 1.6rem;
                display: flex;
                align-items:center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                transition: 0.3s ease;

                }
            }
            .text {
                font-weight: 500;
                color: $color-grey;
                transition: 0.3s ease;

            }

            .icon-arrow {
                i {
                    color: $color-grey;
                transition: 0.3s ease;

                }
            }
        }
        &-inner {
            position:absolute;
            left:0;
            top: 100%;
            width: 100%;
            z-index: 5;
            background: white;
            padding: 1.6rem;
            transform: translateY(2rem);
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease-in-out;
            border: 0.1rem solid black;

            &-bot {
                margin-top: 2.4rem;
                .link {
                    text-decoration: underline;
                }
            }

            @include max(768px) {
                position:fixed;
                top: 0;
                height: 100%;
                width: 100%;left: 0;
                z-index: 100;
            }

        }
        &.show {
            .pcollec-fi-inner {
                transform: translate(0);
                opacity: 1;
                visibility: visible;
            }

            .icon-arrow {
                i {
                    transform: rotate(180deg);
                }
            }
            .pcollec-fi-top {
                .icon {
                    img {
                        filter: brightness(0) invert(0);
                    }
                }
                .text {
                    color: black;
                }
                .icon-arrow {
                    i {
                        color: black;
                    }
                }
            }
        }

        &-flex {
            display: flex;
            margin: 0 -0.4rem;
            @include max(768px) {
                flex-direction: column;
                row-gap: 3rem;
            }
        }

        &-col {
            width: calc(100% / 3);
            padding: 0 0.4rem;

            @include max(768px) {
                width: 100%;
            }

            .title {
                margin-bottom: 1.2rem;
            }

            &:nth-child(1) {
                .recheck {

                    &-block {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 0.8rem;
                    }
        
                    &-checkbox {
                        width: 2.8rem;
                        height: 2.8rem;
                        border-radius: 100rem;
                        padding: 0.3rem;
                        border: none;
                        background: none !important;
                        border: 0.1rem solid $color-grey-3;
        
                        &::before{
                            display: none;
                        }
                    }
                    &-item {
                        &.active {
                            .recheck-checkbox {
                                border: 0.1rem solid black;
                            }
        
                            
                        }
                    }
                }
            }

            &.pcollec-fi-check {
                .recheck {
                    &-block {
                        display:flex;
                        flex-direction:column;
                        gap: 0.8rem;
                    }
                    &-checkbox {
                        width: 1.6rem;
                        height: 1.6rem;
                        border: 0.1rem solid $color-grey-3;
                        border-radius: 0.4rem;

                        &::before {
                            font-weight: 600;
                            font-size: 1.2rem;
                        }
                    }
                    &-item {
                        &.active {
                            .recheck-checkbox {
                                background:$color-second;
                                border-color: $color-second;
                            }
                        }
                    }
                }
            }
        }  

        &-mb {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2.4rem;
            margin-top: -1.6rem;

            @include min(768.99px) {
                display: none;
            }
        }
        
        &-close {
            cursor: pointer;
            .text{
                font-size:2.8rem;
            }
            &.left {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                i {
                    font-size:2rem;
                }
            }
            &.right {
                width:4.4rem;
                height:4.4rem;
                background:$color-pri;
                padding: 1.5rem;
                transform: translateX(1.6rem);

                img {
                    width: 100%;object-fit: contain;
                    filter: brightness(0) invert(1);
                }
            }
        }

       
    }
    &-sx {
        display: flex;
        align-items:center;
        gap: 0.4rem;
        position: relative;
        .text {
            color:$color-grey;
            transition: 0.3s ease;

        }
        .icon-arrow {
            i {
                color:$color-grey;
                transition: 0.3s ease;
            }
        }
        &-top {
            display: flex;
            align-items:center;
            gap: 0.4rem;
            cursor: pointer;
            padding: 1rem 0;
            .text {
                font-weight: 500;
                color: $color-grey;
            }

            .icon-arrow {
                i {
                    color: $color-grey;
                }
            }
        }
        &-inner {
            position: absolute;
            top: 100%;
            border: 0.1rem solid black;
            left: 0;
            width: max-content;
            z-index: 5;
            background: white;
            padding: 1.6rem;
            transform: translateY(2rem);
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease-in-out;

            .recheck-block {
                display: flex;
                flex-direction: column;
                gap: 0.8rem;
            }
            .recheck-checkbox {
                border-radius: 100rem;
                width: 1.6rem;
                height: 1.6rem;
                transition: 0;

                &::after {
                    position: absolute;
                    content: "";
                    background: black;
                    left: 50%;
                    top:50%;
                    transform: translate(-50%,-50%) ;
                    width: calc(100% - 0.6rem);
                    height: calc(100% - 0.6rem);
                    border-radius: 100rem;
                    opacity: 0;
                    
                }
            }
            .recheck-item.active .recheck-checkbox {
                background: none;

                &:before {
                    display: none;
                }

                &::after {
                    opacity: 1;
                }
            }
        }

        &.show {
            .pcollec-sx-inner {
                transform: translate(0);
                opacity: 1;
                visibility: visible;
            }

            .icon-arrow {
                i {
                    transform: rotate(180deg);
                }
            }
            .pcollec-sx-top {
                .icon {
                    img {
                        filter: brightness(0) invert(0);
                    }
                }
                .text {
                    color: black;
                }
                .icon-arrow {
                    i {
                        color: black;
                    }
                }
            }
        }
    }
    &-tf {
        margin-top: 0.8rem;
        &-top {
            display: flex;
            justify-content:space-between;
            align-items:center;
            margin-bottom:0.8rem;
            .title {
                 font-size:5.2rem;
                 line-height: 1.2;
            }
            @include max(768px) {
                .title {
                    font-size:4.4rem;
                }
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }
        .pcollec-tf-1-item {
            flex-direction: column;
            gap: 0.8rem;
            align-items: center;
            display: flex;
            cursor: pointer;
        }
        .recheck {
            &-block {
                // display: flex;
                // gap: 0.8rem;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
            &-item {
                flex-direction: column;
                gap: 0.8rem;
                align-items: center;


                &.active {
                    .recheck-checkbox {
                        border-color:black;
                    }
                    .recheck-text {
                        color: black;
                        font-weight:600;
                    }
                }
            }
            &-checkbox {
                width:13rem;
                height:18rem;
                background: white !important;
                border: 1px solid  transparent;

                img {
                    height: 100% !important;
                    width: 100% !important;
                    object-fit: cover;
                }
         
                &:before {
                    display:none;
                }
                @include max(768px) {
                    width: 11rem;
                    position: relative;
                    padding-top: 125%;
                    overflow: hidden;
                    display: block;
                    height:unset;

                    img {
                        @include imgContain;
                    }
                }
            }
            &-text {
                font-weight: 500;
                margin: 0;
            }
        }
        .swiper {
            &-slide {
                width: max-content;
            }
        }

        &-2 {
            .recheck {
                &-text {
                    padding: 0.8rem 1.2rem;
                    border-radius: 10rem;
                    border: 1px solid  $color-grey-3;
                    font-weight: 500;
                    @include mid-flex;
                    line-height: 1;
                    height: 3.5rem;
                    color: #474140;

                    &.active {
                        color: black;
                        background: #ECEBEA;
                        border-color: #ECEBEA;
                    }
                }
                &-item {
                    &.active {
                        .recheck-text {
                            color: black;
                            background: #ECEBEA;
                            border-color: #ECEBEA;

                        }
                    }
                }
            }
        }
        &-3 {
            .recheck {
                &-block {
                    // display: flex;
                    // gap: 0.8rem;
                    overflow-x: auto;
    
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                &-item {
                    align-items: flex-start;
                    border: 0.1rem solid transparent;

    
    
                    &.active {
                        border: 0.1rem solid black;

                        .recheck-checkbox {
                            border:none;
                        }
                    }
                }
                &-checkbox {
                    width:15rem;
                    position: relative;
                    padding-top:calc((200/150) * 100%);
                    border: none;

                    img {
                        @include imgCover;
                    }

                    @include max(768px) {
                        width: 10rem;
                    }
                }
           
            }
        }
    }

}

.scate {
    &-list {

    }
    &-item {
        &.active {
            >.scate-link {
                color: $color-second;
            }
        }
        >.scate-list {
            padding-left: 2rem;
        }
    }
    &-link {
        font-weight: 600;
        color: #807D7C;
        padding: 0.8rem 0;
        display: block;
        transition: 0.3s ease-in-out;

        @include hover {
            color: $color-second;
        }
    }
}

.spart {
    &-wrap {
        padding-top: 6rem;

        @include max(992px) {
            padding-top:0;
        }
    }
    &-top {
        padding: 6rem 0;



        .title {
            font-size:4.4rem;
            line-height: 1.2;
        }
        @include max(992px) {
            padding:4rem 0;
            padding-top: 6rem;

            .title {
                font-size:3.6rem;
            }
        }
    }
    &-img {
        position: relative;
        overflow: hidden;
        padding-top: calc((360/300) * 100%);

        img {
            @include imgCover;
            transition: 0.7s ease-in-out;
            height: 100% !important; 
        }
        @include hover {
            img {
                transform: translate(-50%,-50%) scale(1.1);
            }
        }
    }
    .swiper {
        &-wrapper {
            transition-timing-function:linear !important;
        }
        &-slide {
            // width: max-content;
        }
    }
}

.pwish {
    &-inner {
        padding: 2rem 0;
    }
    &-title {
        font-size: 5.2rem;
        font-weight: 700;

        @include max(768px){
            font-size:4.4rem;
        }
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        row-gap: 2rem;
        margin: 0 -0.4rem;
        margin-top: 2rem;

        .pro-item {
            width: 25%;
            padding: 0 0.4rem;

            @include max(992px) {
                width: calc(100% / 3);
            }
            @include max(650px) {
                width: 50%;
            }
        }

        .icon-remove {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            z-index: 2;
            cursor: pointer;
            padding: 0.5rem;

            i {
                font-size:2rem;

            }
        }
    }
}

