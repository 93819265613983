.pcart {
  border-bottom: 0.1rem solid $color-pri;
  &-flex {
    display: flex;
    margin: 0 -4rem;

    @include max(1200px) {
      margin: 0 -1rem;
    }
    @include max(768px) {
      flex-direction: column-reverse;
    }
  }
  &-left {
    padding: 4rem;
    width: calc((7 / 12) * 100%);
    border-right: 0.1rem solid $color-pri;

    @include max(1200px) {
      padding: 2.4rem 1rem;
    }
    @include max(1100px) {
      width: 50%;
    }
    @include max(768px) {
      width: 100%;
      border-right: none;
    }

    .title {
      @include fz-28;
      line-height: 1.2;
      margin-bottom: 1.2rem;

      @include max(992px) {
        font-size: 2.8rem;
      }
    }
  }
  &-right {
    padding: 4rem;
    width: calc((5 / 12) * 100%);
    @include max(1200px) {
      padding: 2.4rem 1rem;
    }
    @include max(1100px) {
      width: 50%;
    }
    @include max(768px) {
      width: 100%;
    }

    .title {
      @include fz-28;
      line-height: 1.2;
      margin-bottom: 1.2rem;
      @include max(992px) {
        font-size: 2.8rem;
      }
    }
    .pdp-dk {
      display: block !important;
    }
  }

  &-form {
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 - 0.6rem;

      .ip-control {
        padding: 0 0.6rem;
      }
    }
    &-btn {
      margin-top: 2.4rem;
    }
    &-bot {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      gap: 2rem;
      flex-wrap: wrap;

      @include max(992px) {
        justify-content: flex-start;
        gap: 1rem;
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .icon {
          display: flex;
          width: 2rem;
          height: 2rem;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
        .text {
          flex: 1;
          line-height: 1;
          font-size: 1.2rem;
        }
      }
    }
  }
  &-method {
    margin-top: 4rem;
    .recheck {
      &-text {
        flex: 1;
        margin-left: 2rem;
        &-top {
          display: flex;
          justify-content: space-between;
        }
        &-img {
          display: flex;
          align-items: center;
          gap: 1.2rem;

          .img {
            height: 4.4rem;
            width: 4.4rem;
            @include mid-flex;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .txt {
            color: #807d7c;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .icon {
              display: flex;
              width: 1.6rem;
              height: 1.6rem;

              img {
                width: 100%;
                object-fit: contain;
              }
            }
            .text {
              display: flex;
              align-items: center;
            }
          }
        }
      }
      &-item {
        align-items: center;
        padding-bottom: 1.2rem;

        &:last-child {
          padding-bottom: 0;
        }
        &.active {
          .recheck-dot {
            border-color: $color-pri;
          }
          .txt {
            color: $color-pri;
          }
        }
      }
      &-dot {
        width: 2rem;
        height: 2rem;
        border: 0.1rem solid $color-grey-3;
        &:before {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .ip-control {
    height: 4.4rem;
    position: relative;
    margin-bottom: 1.2rem;

    &.x3 {
      @include max(992px) {
        width: 100%;
      }
    }

    .text-abs {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.4s;
      pointer-events: none;
      z-index: 2;
      left: 2.4rem;
      line-height: 1;
      color: #807d7c;
    }

    .icon-abs {
      position: absolute;
      left: 1.6rem;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    input {
      outline: none;
      width: 100%;
      border: 1px solid #f3edea;
      transition: 0.3s;
      height: 4.8rem;
      padding: 0 1.6rem;
      font-weight: 500;
      background: #f9f7f5;
    }
    .select2-container {
      width: 100% !important;
      height: 100%;
      font-size: 1.2rem;
    }
    .select2-search--dropdown {
      display: none;
    }
    .select2-container .select2-selection--single,
    .select2-selection__rendered {
      height: 100%;
      display: flex;
      align-items: center;
      background: #f9f7f5;
      border-radius: 0;
      width: 100%;
      border-color: transparent;
    }
    .select2-selection__rendered {
      border: 1px solid #f3edea;
    }
    .select2-selection__arrow {
      height: 100% !important;
    }
    &.gc {
      input {
        padding-left: 4rem;
      }
    }
    &.active {
      .text-abs {
        transform: translateY(0);
        top: 0.5rem;
        font-size: 1rem;
      }
      input {
        padding-top: 1.5rem;
      }
    }
  }

  &-row {
    padding: 2.4rem 0;
    border-top: 0.1rem solid $color-grey-3;
    &:first-child {
      border-top: none;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    max-height: 26rem;
    overflow-y: auto;
    padding-right: 1rem;
    margin-right: -1rem;
  }

  &-item {
    display: flex;
    gap: 2.4rem;

    @include max(992px) {
      gap: 1.6rem;
    }

    &-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .current {
        font-weight: 500;
      }
      .old {
        font-size: 1.2rem;
        color: $color-grey;
        text-decoration: line-through;
        font-weight: 500;
      }
    }
  }
  &-img {
    width: 12rem;
    height: 12rem;

    @include max(992px) {
      width: 10rem;
      height: 10rem;
    }

    .box {
      padding-top: 100%;
      position: relative;
      overflow: hidden;
      display: block;

      img {
        @include imgContain;
      }
    }
  }
  &-desc {
    flex: 1;
    &-top {
      display: flex;
      justify-content: space-between;

      .icon {
        width: 2.4rem;
        padding: 0.2rem;
        cursor: pointer;

        i {
          font-size: 2rem;
        }
      }
    }
    &-bot {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      margin-top: 1.2rem;
    }
  }
  &-name {
    display: flex;
    flex-direction: column;
    font-weight: 600;

    .attr {
      color: $color-grey;
      font-weight: 500;
      font-size: 1.2rem;
    }
  }
  &-code {
    &-input {
      display: flex;
      gap: 1.2rem;

      input {
        outline: none;
        width: 100%;
        border: 1px solid #f3edea;
        transition: 0.3s;
        height: 4.4rem;
        padding: 0 1.6rem;
        font-weight: 500;
        background: #f9f7f5;
      }
      .btn {
        min-width: fit-content;
      }
    }
  }
  &-promo {
    margin-top: 0.8rem;

    &-list {
      display: flex;
      gap: 0.8rem;
      flex-wrap: wrap;
    }

    &-left {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    &-right {
      display: flex;
      align-items: center;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      height: 3.4rem;
      border-radius: 10rem;
      background: #ecebea;
      padding: 0 1rem;
      .text {
        font-size: 1.2rem;
        font-weight: 600;
        color: #474140;
        line-height: 1;
      }
      .icon {
        width: 1.6rem;
        height: 1.6rem;
        flex-shrink: 0;
        @include mid-flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        i {
          color: #474140;
          font-size: 1.6rem;
          font-weight: 500;
        }

        &-remove {
          cursor: pointer;
        }
      }
    }
    &-text {
      color: #0bae62;
      font-weight: 500;
      margin-top: 0.5rem;
    }
  }
  &-tag {
    margin-top: 1.6rem;
    margin-right: -4rem;
    &-item {
      border: 0.1rem solid $color-grey-3;
      border-radius: 0.8rem;
      height: 100%;
    }
    &-box {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &-top {
      padding: 1.2rem;
      border-bottom: 0.1rem dashed $color-grey-3;
      flex: 1;

      .code {
        font-weight: 700;
        font-size: 1.6rem;
        height: 3.2rem;
        background-color: #fbc358;
        @include mid-flex;
        padding: 0 0.8rem;
      }
      .text {
        font-size: 1.2rem;
        font-weight: 500;
        color: #474140;
      }
    }
    &-bot {
      padding: 0.8rem 1.2rem;

      .pcart-tag-flex {
        justify-content: space-between;
      }

      .txt {
        font-size: 1.2rem;
        font-weight: 600;
      }
      .link {
        font-weight: 600;
        text-decoration: underline;
        font-size: 1.2rem;
      }
    }
    &-flex {
      display: flex;
      gap: 0.8rem;
      flex-wrap: wrap;
    }
    .swiper {
      padding: 0.1rem 0;

      &-slide {
        height: auto !important;
      }
    }
  }
  &-total {
    &-flex {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      margin-bottom: 0.8rem;

      &:last-child {
        padding-bottom: 0;
      }

      .text {
        font-weight: 500;
        color: $color-grey;
        display: flex;
        flex-direction: column;

        .txt {
          font-size: 1.2rem;
        }
      }
      .fee {
        font-weight: 500;
      }

      &.total {
        .text {
          font-weight: 600;
          @include fz-20;
          color: $color-pri;
        }
        .fee {
          font-weight: 600;
          @include fz-20;
        }

        @include max(768px) {
          .text,
          .fee {
            font-size: 2rem;
          }
        }
      }
    }
  }
  .quantity {
    .count {
      border-color: $color-pri;
      &-btn {
        width: 2.4rem;
        height: 2.4rem;
        background: none;

        i {
          color: $color-grey;
          transition: 0.3s ease-in-out;
        }
        @include hover {
          i {
            color: $color-pri;
          }
        }
      }
    }
  }
  &-suc {
    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        width: 6rem;
        height: 6rem;
        margin-bottom: 0.8rem;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .title {
        font-size: 4.4rem;
        line-height: 1.2;
        margin-bottom: 0;
      }
      .code {
        height: 4rem;
        border-radius: 10rem;
        @include mid-flex;
        border: 0.1rem solid $color-pri;
        padding: 0 2rem;
        margin-top: 2.4rem;
      }
    }
    &-btn {
      margin-top: 2.4rem;
    }
    &-bot {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      gap: 0.8rem;

      .text {
      }
      .link {
        @include hover {
          text-decoration: underline;
        }
      }
    }
  }
  &-info {
    padding: 2.4rem;
    background: #f9f7f5;
    margin-top: 2.4rem;

    .title {
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
    }
  }
  &.p-success {
    @include max(768px) {
      .pcart-right {
        display: none;
      }
    }
  }
}
.select2-search--dropdown {
  display: none;
}
.pcollec-main {
  .empty-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-empty-product {
      display: block;
      width: 40%;
    }
    .text {
      text-align: center;
      margin-top: 1rem;
    }
    .btn {
      margin-top: 2.4rem;

      .text {
        margin-top: 0;
      }
    }
  }
}
.cartod-form {
  .woocommerce-input-wrapper,.form-list {
    input {
      outline: none;
      width: 100%;
      border: 1px solid #f3edea;
      transition: 0.3s;
      height: 4.8rem !important;
      padding: 0 1.6rem;
      font-weight: 500;
      background: #f9f7f5;
      line-height: 1 !important;
      font-family: "Montserrat" !important;
    }
    textarea {
      outline: none;
      width: 100%;
      border: 1px solid #f3edea;
      transition: 0.3s;
      padding: 0 1.6rem;
      font-weight: 500;
      background: #f9f7f5;
      line-height: 1 !important;
      font-family: "Montserrat" !important;
      min-height: 4.8rem !important;
      position: relative;
      padding-top: 1.4rem !important;

      &::before {
        position: absolute;
        content: "";
        left: 1.6rem;
        top: 50%;
        transform: translateY(-50%);
        width: 2rem;
        height: 2rem;
        background-image: url(../../../template/assets/images/icon-ghichu.svg);
        background-repeat: no-repeat;
        background-size:contain;
      }
    }
  }
  .select2-container--default .select2-selection--single {
    height: 4.8rem !important;
    display: flex;
    align-items: center;
    background: #f9f7f5;
    border-radius: 0;
    width: 100% !important;
    border: 1px solid #f3edea !important;

    .select2-selection__arrow {
      height: 100% !important;
    }
  }
}
.woocommerce form .form-row .select2-container {
  height: 4.8rem;
}
.woocommerce-shipping-fields__field-wrapper {
  width: 100%;
}
