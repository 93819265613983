.page-numbers {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    margin-top: 1.6rem;

    >li {
        &:not(:first-child) {
            margin-left: 1rem;
        }

        &>.page-numbers {
            height: 3.2rem;
            width: 3.2rem;
            border-radius: 0.5rem;
            @include mid-flex;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            color:rgba(133, 140, 130, 1);
            @include fz-14;
            font-weight: 400;
            transition: 0.4s ease;
            @include affter($color-second);
            line-height:1.5;
            // border: 1px solid rgba(31, 67, 151, 0.10);



            &.next,
            &.prev {
                background-color:#F6F7F5;
                i {
                    color: $color-black;
                    font-weight: 700;
                }
            }

            &:after {
                z-index: -1;
                background: $color-pri;
            }

            @include hover {
                &:not(.dot) {
                    color: white;

                    &::after {
                        left: 0; 
                        width: 100%;
                    }
                }
            }

            &.current {
                color: $color-white;
                font-weight: 700;
                border: 0.2rem solid $color-pri;
                background-color: $color-pri;

                
            }

            &.dot {
                background-color: transparent;
            }

            i {
                font-weight: 400;
            }
        }
    }
}